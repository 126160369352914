import * as React from 'react';
import MyTypes from 'MyTypes';
import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap'; 
import _ from 'lodash'; 
import * as models from '../../models';
import NumberFormat from 'react-number-format'; 
import { companyActions } from 'store/companies';

interface Props extends PropsFromRedux {  
    showModal: boolean;
    closeModal: () => void;
    callback: (contact: models.ContactSimple) => void;
    defaultValues?: models.ContactSimple; 
}

interface State {
    firstName: string; 
    lastName: string; 
    email: string; 
    companyName: string; 
    cellNumber: string; 
    homeNumber: string; 
    workNumber: string; 
}

class AddContactSimple extends React.Component<Props, State> {
    public state = {
        firstName: "",
        lastName: "",
        email: "",
        companyName: "",
        cellNumber: "", 
        homeNumber: "", 
        workNumber: ""
    };

    componentDidMount() {
        if(this.props.allCompanyDD.totalCount < 1)
            this.props.requestAllCompaniesDD(); 
        this.moveDefaultsToState;
    }

    componentDidUpdate(prevProps: any){
        if (  !_.isEqual(prevProps.defaultValues,this.props.defaultValues)) {      
            this.moveDefaultsToState(); 
        }
    }

    public moveDefaultsToState = () => {
        if(this.props.defaultValues && this.props.defaultValues.companyName.length > 0) {
            this.setState({companyName: this.props.defaultValues.companyName }); 
        }
    }

    public resetFormStateAndClose = () => {
        this.setState({
            firstName: "",
            lastName: "",
            email: "",
            companyName: "",
            cellNumber: "", 
            homeNumber: "", 
            workNumber: ""
        }); 
        this.props.closeModal(); 
    }

    public submitForm = (e:any) => {
        e.preventDefault();
        const contactSimple: models.ContactSimple = {
            ...models.defaultContactSimple,
            fullName: this.state.firstName + ' ' + this.state.lastName, 
            ...this.state,
        }        
        this.props.callback(contactSimple);
        this.resetFormStateAndClose(); 
    }

    public render() {
        const { allCompanyDD, closeModal, defaultValues, isAllCompanyDDLoading, showModal } = this.props;
        
        return (
            <Modal centered={true} show={showModal} onHide={closeModal} >                                
                <Modal.Header closeButton>
                    <Modal.Title>Create Contact</Modal.Title>
                </Modal.Header>
                <Form className="claim-modal" onSubmit={(e) => this.submitForm(e)}>                    
                    <Modal.Body>
                        <Form.Group as={Row} controlId="company" className="input-field-container">
                            <Form.Label column sm="4">Company</Form.Label>
                            <Col sm="8">
                                <Form.Select name="insCompany" value={this.state.companyName} onChange={(e) => this.setState({companyName: e.target.value})} >
                                        {isAllCompanyDDLoading && <option>Loading...</option>}
                                        {!isAllCompanyDDLoading && <option key={-1}>Choose...</option>}
                                        {allCompanyDD.totalCount > 0 && allCompanyDD.resourceList.map((c: models.DropDownListItem, i: number) => ( 
                                            <option key={i} value={c.name} >{c.name}</option>
                                        ))}
                                </Form.Select>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="firstName" className="input-field-container">
                            <Form.Label column sm="4">First Name</Form.Label>
                            <Col sm="8">
                                <Form.Control type="text" placeholder='Enter First Name...' onChange={(e) => this.setState({firstName: e.target.value})} />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="lastName" className="input-field-container">
                            <Form.Label column sm="4">Last Name</Form.Label>
                            <Col sm="8">
                                <Form.Control type="text" placeholder='Enter Last Name...' onChange={(e) => this.setState({lastName: e.target.value})} />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="email" className="input-field-container">
                            <Form.Label column sm="4">Email</Form.Label>
                            <Col sm="8">
                                <Form.Control type="text" placeholder='Enter Email...' onChange={(e) => this.setState({email: e.target.value})} />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="input-field-container" >
                            <Form.Label column sm="4">Cell Number</Form.Label>
                            <Col>
                                <NumberFormat 
                                    format="(###) ###-####" 
                                    placeholder="(xxx) xxx-xxxx"
                                    displayType='input'
                                    className="number-format-input"
                                    value={this.state.cellNumber}
                                    onValueChange={(values) => {
                                        const { value } =values; 
                                        this.setState({cellNumber: value}); 
                                    }}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="input-field-container" >
                            <Form.Label column sm="4">Work Number</Form.Label>
                            <Col>
                                <NumberFormat 
                                    format="(###) ###-####" 
                                    placeholder="(xxx) xxx-xxxx"
                                    displayType='input'
                                    className="number-format-input"
                                    value={this.state.workNumber}
                                    onValueChange={(values) => {
                                        const { value } =values; 
                                        this.setState({workNumber: value}); 
                                    }}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="input-field-container" >
                            <Form.Label column sm="4">Home Number</Form.Label>
                            <Col>
                                <NumberFormat 
                                    format="(###) ###-####" 
                                    placeholder="(xxx) xxx-xxxx"
                                    displayType='input'
                                    className="number-format-input"
                                    value={this.state.homeNumber}
                                    onValueChange={(values) => {
                                        const { value } =values; 
                                        this.setState({homeNumber: value}); 
                                    }}
                                />
                            </Col>
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.resetFormStateAndClose}>
                            CANCEL
                        </Button>
                        <Button variant="primary" type="submit">
                            SAVE
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>            
        );
    }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
   allCompanyDD: state.companies.dropdowns.allCompaniesDD,
   isAllCompanyDDLoading: state.companies.dropdowns.isAllCompanyDDLoading,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            requestAllCompaniesDD: companyActions.requestAllCompaniesDD,
        },
        dispatch
    );

const connector = connect(mapStateToProps, mapDispatchToProps); 
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(AddContactSimple);