import { call, put, take, takeEvery } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import services from '../../services';
import * as models from '../../models'; 
import * as actions from './actions';
import * as constants from './constants';

export default function* () {
    yield takeEvery(constants.REQUEST_STATE_ABBR_DD, requestStateAbbrDD); 
    yield takeEvery(constants.REQUEST_STATE_DD, requestStateDD); 
    yield takeEvery(constants.REQUEST_ZIPSEARCH, requestZipSearch); 
}


// ZipSearch
export function* requestZipSearch(action: ActionType<typeof actions.requestZipSearch>): any {
    const userRequest = yield call(services.api.locations.getZipSearchResponse, action.payload.zipCode); 
    if (userRequest.isSuccess) {
        yield put(actions.receiveZipSearch(userRequest.data)); 
    }
}

// Dropdowns
export function* requestStateAbbrDD(action: ActionType<typeof actions.requestStateAbbrDD>): any {
    const userRequest = yield call(services.api.locations.getStatesAbbrDD); 
    if (userRequest.isSuccess) {
        yield put(actions.receiveStateAbbrDD(userRequest.data)); 
    }
}

export function* requestStateDD(action: ActionType<typeof actions.requestStateDD>): any {
    const userRequest = yield call(services.api.locations.getStatesDD); 
    if (userRequest.isSuccess) {
        yield put(actions.receiveStateDD(userRequest.data)); 
    }
}