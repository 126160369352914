import { all, call, put, takeEvery, select, takeLatest } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import { createPatch } from 'rfc6902';
import services from '../../services';
import * as models from '../../models'; 
import * as actions from './actions';
import * as constants from './constants';
import { claimsActions } from 'store/claims';
import { USER_ACTIONS } from '../../store/enums'; 
import { userActions } from 'store/user';
import { AppToastTypes } from 'models/enums';

export default function* () {
    yield takeEvery(constants.ADD_FEE, addFee); 
    yield takeEvery(constants.ADD_FEE_TO_SCHEDULE, addFeeToSchedule); 
    yield takeEvery(constants.CREATE_PAYMENT, addPayment); 
    yield takeEvery(constants.ADD_PAYROLL, addPayroll); 
    yield takeEvery(constants.CREATE_FEE_SCHEDULE, createFeeSchedule); 
    yield takeEvery(constants.TOGGLE_FEE_SCHEDULE_STATUS, toggleFeeScheduleStatus); 
    yield takeEvery(constants.ADD_INVOICE_GROUP, addInvoiceGroup); 
    yield takeEvery(constants.ADD_INVOICE_TO_GROUP, addInvoiceToGroup); 
    yield takeEvery(constants.BUILD_PDF, buildPDF); 
    yield takeEvery(constants.DELETE_FEE, deleteFee); 
    yield takeEvery(constants.DELETE_FEE_SCHEDULE, deleteFeeSchedule); 
    yield takeEvery(constants.PATCH_FEE, patchFee); 
    yield takeEvery(constants.PATCH_FEE_SCHEDULE, patchFeeSchedule); 
    yield takeEvery(constants.PATCH_INVOICE, patchInvoice); 
    yield takeEvery(constants.REPLACE_INVOICE, replaceInvoice); 
    yield takeEvery(constants.REMOVE_INVOICE_FROM_GROUP, removeInvoiceFromGroup); 
    yield takeEvery(constants.REQUEST_ADJUSTER_FEES, requestAdjusterFees); 
    yield takeEvery(constants.REQUEST_ADJUSTER_FEES_LIST, requestAdjusterFeesList); 
    yield takeEvery(constants.REQUEST_FEE_LIST, requestFeeList);
    yield takeEvery(constants.REQUEST_FEE_SCHEDULE, requestFeeSchedule); 
    yield takeEvery(constants.REQUEST_FEE_SCHEDULE_BY_COMPANY, requestFeeScheduleList); 
    yield takeEvery(constants.REQUEST_INVOICE, requestInvoice); 
    yield takeEvery(constants.REQUEST_INVOICES_CSV, requestInvoicesCSV); 
    yield takeEvery(constants.REQUEST_INVOICE_LIST, requestInvoiceList); 
    yield takeEvery(constants.REQUEST_INVOICE_ADJUSTERS_DD, requestInvoiceAdjusterDD); 
    yield takeEvery(constants.REQUEST_INVOICE_COMPANY_DD, requestInvoiceCompanyDD); 
    yield takeEvery(constants.REQUEST_INVOICE_GROUP_LIST, requestInvoiceGroupList); 
    yield takeEvery(constants.REQUEST_INVOICE_GROUP_DD, requestInvoiceGroupDD);     
    yield takeEvery(constants.REQUSET_PAYROLL_ZIPFILE, requestPayrollZipFile); 
    yield takeEvery(constants.REQUEST_RECONCILE_LIST, requestReconcileList); 
    yield takeEvery(constants.REQUEST_RECONCILE_LIST_BULK, requestReconcileListBulk);     
}

export function* addFee(action: ActionType<typeof actions.addFee>): any {
    const userReqeust = yield call(services.api.financials.addFee, action.payload.fee); 
    if (userReqeust.isSuccess) {
        yield put(actions.requestFeesList()); 
    }
}

export function* addFeeToSchedule(action: ActionType<typeof actions.addFeeToSchedule>): any {
    const userReqeust = yield call(services.api.financials.addFeeToSchedule, action.payload.feeScheduleId, action.payload.fee); 
    if (userReqeust.isSuccess) {
        yield put(actions.requestFeesList()); 
    }
}

export function* addInvoiceGroup(action: ActionType<typeof actions.addInvoiceGroup>): any {
    const userReqeust = yield call(services.api.financials.addInvoiceGroup, action.payload.invoiceGroup); 
    if (userReqeust.isSuccess) {
        const invoiceGroupRequest: models.InvoiceGroupsRequest = {
            ...models.defaultInvoiceGroupsRequest,
            companyName: action.payload.invoiceGroup.insCompany
        }
        yield put(actions.requestInvoiceGroupsList(invoiceGroupRequest)); 
    }        
}

export function* addInvoiceToGroup(action: ActionType<typeof actions.addInvoiceToGroup>): any {
    const userRequest = yield call(services.api.financials.getInvoice, action.payload.invoiceId); 
    if (userRequest.isSuccess) {
        const newInvoice: models.Invoice = {
            ...userRequest.data,
            invoiceGroup: action.payload.groupName, 
        }
        const patch = createPatch(userRequest.data, newInvoice);         
        yield put(actions.patchInvioce(action.payload.invoiceId, patch)); 
    }
    else {
        console.log(userRequest); 
    }
}

export function* addPayment(action: ActionType<typeof actions.createPayment>): any {
    const userRequest = yield call(services.api.financials.addPayment, action.payload.request); 
    if (userRequest.isSuccess){
        const state = yield select(); 
        yield put(actions.requestInvoiceList(state.financials.financials.invoicesRequest)); 
    }
    else {
        console.log(userRequest); 
    }
}

export function* addPayroll(action: ActionType<typeof actions.addPayroll>): any {
    const userRequest = yield call(services.api.financials.addPayroll, action.payload.request);     
    if (userRequest.isSuccess){
        yield put(actions.addPayrollComplete());         
        yield put(actions.requestPayrollZipFile(userRequest.data));
    }
    else {
        console.log(userRequest); 
    }
}

export function* createFeeSchedule(action: ActionType<typeof actions.createFeeSchedule>): any {
    console.log(action.payload.request); 
    const userRequest = yield call(services.api.financials.createFeeSchedule, action.payload.request); 
    if (userRequest.isSuccess){    
        yield put(actions.requestFeeSchedulesByCompany(action.payload.request.insuranceCompany));         
    }
    else {
        console.log(userRequest); 
    }    
}

export function* buildPDF(action: ActionType<typeof actions.buildPDF>): any {
    yield put(userActions.setLastUserAction(USER_ACTIONS.INVOICE_BUILDING_PDF)); 
    const userRequest = yield call(services.api.financials.buildPDF, action.payload.invoiceId); 
    if (userRequest.isSuccess) {        
        const state = yield select(); 
        const invoicesRequest = state.financials.financials.invoicesRequest; 
        const claimId = state.claims.claim.claim.claimID; 
        if(claimId && claimId !== 0) {
            yield put(claimsActions.requestInvoices(claimId)); 
        }
        yield put(actions.requestInvoiceList(invoicesRequest)); 
        yield put(userActions.setLastUserAction(USER_ACTIONS.INVOICE_BUILD_PDF_SUCCESS)); 
        yield put(userActions.setAppToast({message: "Invoice PDF Built", type: AppToastTypes.Success})); 
    }
    else {
        yield put(userActions.setLastUserAction(USER_ACTIONS.INVOICE_BUILD_PDF_FAILURE)); 
        yield put(userActions.setAppToast({message: userRequest.data, type: AppToastTypes.Failure})); 
    }
}

export function* deleteFee(action: ActionType<typeof actions.deleteFee>): any {
    const userRequest = yield call(services.api.financials.deleteFee, action.payload.id);
    if (userRequest.isSuccess) {
        yield put(actions.requestFeesList()); 
    }
}

export function* deleteFeeSchedule(action: ActionType<typeof actions.deleteFee>): any {
    const userRequest = yield call(services.api.financials.deleteFeeSchedule, action.payload.id);
    if (userRequest.isSuccess) {
        const state = yield select();
        const responseList: models.FeeScheduleResponse = state.financials.financials.feeSchedulesResponse; 
        const company = responseList.resourceList.find(x => x.feeScheduleId === action.payload.id)?.insuranceCompany!; 
        yield put(actions.requestFeeSchedulesByCompany(company)); 
    }
    else {
        console.log(userRequest); 
    }
}

export function* patchFee(action: ActionType<typeof actions.patchFee>): any {
    const userRequest = yield call(services.api.financials.patchFee, action.payload.feeId, action.payload.patch)
    if (userRequest.isSuccess) {
        yield put(actions.requestFeesList()); 
    }
    else {
        console.log(userRequest); 
    }
}

export function* patchFeeSchedule(action: ActionType<typeof actions.patchFeeSchedule>): any {    
    const userReqeust = yield call(services.api.financials.patchFeeSchedule, action.payload.id, action.payload.patch); 
    if (userReqeust.isSuccess) {        
        yield put(actions.requestFeeSchedule(action.payload.id)); 
        const state = yield select();
        const responseList: models.FeeScheduleResponse = state.financials.financials.feeSchedulesResponse; 
        const company = responseList.resourceList.find(x => x.feeScheduleId === action.payload.id)?.insuranceCompany!; 
        yield put(actions.requestFeeSchedulesByCompany(company)); 
    }
}

export function* patchInvoice(action: ActionType<typeof actions.patchInvioce>): any {    
    yield put(userActions.setLastUserAction(USER_ACTIONS.INVOICE_SAVING)); 
    const userRequest = yield call(services.api.financials.patchInvoice, action.payload.invoiceId, action.payload.patch); 
    if (userRequest.isSuccess) {
        const state = yield select();         
        const claimId = state.claims.claim.claim.claimID; 
        if(claimId && claimId !== 0) {
            yield put(claimsActions.requestInvoices(claimId)); 
        }
        yield put(actions.clearInvoice());   
        yield put(actions.requestInvoiceList(state.financials.financials.invoicesRequest));                 
        yield put(userActions.setLastUserAction(USER_ACTIONS.INVOICE_SAVING_SUCCESS)); 
        yield put(userActions.setAppToast({message: 'Invoice Updated', type: AppToastTypes.Success})); 
    }
    else {
        yield put(userActions.setLastUserAction(USER_ACTIONS.INVOICE_SAVING_FAILURE)); 
        yield put(userActions.setAppToast({message: userRequest.data, type: AppToastTypes.Failure})); 
    }   
}

export function* replaceInvoice(action: ActionType<typeof actions.replaceInvoice>): any {
    yield put(userActions.setLastUserAction(USER_ACTIONS.INVOICE_SAVING)); 
    const userRequest = yield call(services.api.financials.replaceInvoice, action.payload.invoiceId, action.payload.invoice); 
    if(userRequest.isSuccess) {
        yield put(userActions.setLastUserAction(USER_ACTIONS.INVOICE_SAVING_SUCCESS)); 
        yield put(userActions.setAppToast({message: "Invoice Updated", type: AppToastTypes.Success})); 
    }
    else {
        yield put(userActions.setLastUserAction(USER_ACTIONS.INVOICE_SAVING_FAILURE));         
        yield put(userActions.setAppToast({message: userRequest.data, type: AppToastTypes.Success})); 
    }
}

export function* removeInvoiceFromGroup(action: ActionType<typeof actions.removeInvoiceFromGroup>): any {
    const userReqeust = yield call(services.api.financials.getInvoice, action.payload.invoiceId); 
    if (userReqeust.isSuccess) {
        const newInvoice: models.Invoice = {
            ...userReqeust.data,
            invoiceGroup: '', 
        }
        const patch = createPatch(userReqeust.data, newInvoice); 
        yield put(actions.patchInvioce(action.payload.invoiceId, patch)); 
    }
}

export function* requestAdjusterFees(action: ActionType<typeof actions.requestAdjusterFees>): any {
    const userRequest = yield call(services.api.financials.getConnectAdjusterFees, action.payload.adjusterId);
    if (userRequest.isSuccess) {
        yield put(actions.receiveAdjusterFees(userRequest.data)); 
    }
    else {
        yield put(actions.setConnectFeesFlag(false));         
    }
}

export function* requestFeeList(action: ActionType<typeof actions.requestFeesList>): any {
    const userRequest = yield call(services.api.financials.getFees);     
    if (userRequest.isSuccess) {
        yield put(actions.receiveFeesList(userRequest.data)); 
    }
}

export function* requestFeeSchedule(action: ActionType<typeof actions.requestFeeSchedule>): any {
    const userReqeust = yield call(services.api.financials.getFeeSchedule, action.payload.feeScheduleId); 
    if (userReqeust.isSuccess) {
        yield put(actions.receiveFeeSchedule(userReqeust.data)); 
    }
}

export function* requestFeeScheduleList(action: ActionType<typeof actions.requestFeeSchedulesByCompany>): any {
    const userRequest = yield call(services.api.financials.getFeeSchedulesByCompany, action.payload.company);  
    if (userRequest.isSuccess) {
        yield put(actions.receiveFeeSchedulesByCompany(userRequest.data)); 
    }
}

export function* requestInvoice(action: ActionType<typeof actions.requestInvoice>): any {
    const userReqeust = yield call(services.api.financials.getInvoice, action.payload.invoiceId); 
    if (userReqeust.isSuccess) {
        yield put(actions.receiveInvoice(userReqeust.data)); 
    }
    else {
        console.log(userReqeust); 
    }
}

export function* requestInvoicesCSV(action: ActionType<typeof actions.requestInvoicesCSV>): any {
    const userRequest = yield call(services.api.financials.getInvoicesCSV, action.payload.request); 
    if (userRequest){        
        yield put(actions.receiveInvoicesCSV()); 
    }
}

export function* requestInvoiceList(action: ActionType<typeof actions.requestInvoiceList>): any {
    const userReqeust = yield call(services.api.financials.getInvoicesList, action.payload.request); 
    if (userReqeust.isSuccess) {
        yield put(actions.receiveInvoiceList(userReqeust.data)); 
    }
}

export function* requestInvoiceAdjusterDD(action: ActionType<typeof actions.requestInvoiceAdjusterDD>): any {
    const userReqeust = yield call(services.api.financials.getInvoiceAdjusterDD, action.payload.request); 
    if (userReqeust.isSuccess) {
        yield put(actions.receiveInvoiceAdjusterDD(userReqeust.data)); 
    }
}

export function* requestInvoiceCompanyDD(action: ActionType<typeof actions.requestInvoiceCompanyDD>): any {
    const userReqeust = yield call(services.api.financials.getInvoiceCompanyDD, action.payload.request); 
    if (userReqeust.isSuccess) {
        yield put(actions.receiveInvoiceCompanyDD(userReqeust.data)); 
    }
}

export function* requestInvoiceGroupDD(action: ActionType<typeof actions.requestInvoiceGroupDD>): any {
    const userReqeust = yield call(services.api.financials.getInvoiceGroupDD, action.payload.request); 
    if (userReqeust.isSuccess) {
        yield put(actions.receiveInvoiceGroupDD(userReqeust.data)); 
    }
}

export function* requestInvoiceGroupList(action: ActionType<typeof actions.requestInvoiceGroupsList>): any { 
    const userRequest = yield call(services.api.financials.getInvoiceGroups, action.payload.request);  
    if (userRequest.isSuccess) {
        yield put(actions.receiveInvoiceGroupsList(userRequest.data)); 
    }
}

export function* requestPayrollZipFile(action: ActionType<typeof actions.requestPayrollZipFile>): any {
    const userRequest = yield call(services.api.financials.getPayrollZipFile, action.payload.payrollId);     
    if (userRequest){ 
        yield put(actions.receivePayrollZipFile());
    }
    else {
        console.log(userRequest); 
    }
}

export function* requestReconcileList(action: ActionType<typeof actions.requestReconcileList>): any {    
    let invoiceList: models.Invoice[] = new Array<models.Invoice>(); 
    for(const invoiceId of action.payload.request){
        const userRequest = yield call(services.api.financials.getInvoice, invoiceId);         
        if (userRequest.isSuccess){            
            const invoice: models.Invoice = userRequest.data; 
            invoiceList = invoiceList.concat(invoice); 
        }
    }
    const reconcileList: models.InvoicesFullResponse = {
        ...models.defaultResponse, 
        totalCount: invoiceList.length,
        resourceList: invoiceList, 
    }
    yield put(actions.receiveReconcileList(reconcileList)); 
}

export function* requestReconcileListBulk(action: ActionType<typeof actions.requestReconcileListBulk>): any {    
    const userRequest = yield call(services.api.financials.getInvoicesByBulkName, action.payload.request);     
    if(userRequest.isSuccess){
        yield put(actions.receiveReconcileList(userRequest.data)); 
    }
}

export function* requestAdjusterFeesList(action: ActionType<typeof actions.requestAdjusterFeesList>): any {        
    const userReqeust = yield call(services.api.financials.getAdjusterFeeList, action.payload.request); 
    if(userReqeust.isSuccess){
        yield put(actions.receiveAdjusterFeesList(userReqeust.data)); 
    }
}

export function* toggleFeeScheduleStatus(action: ActionType<typeof actions.toggleFeeScheduleStatus>): any {
    const userRequest = yield call(services.api.financials.getFeeSchedule, action.payload.id); 
    if(userRequest.isSuccess) {
        const feeSchedule = userRequest.data; 
        const newFeeSchedule: models.FeeSchedule = {
            ...feeSchedule, 
            feeScheduleFeeList: [...feeSchedule.feeScheduleFeeList],
            feeScheduleRangeList: [...feeSchedule.feeScheduleRangeList],
            catCodeList: [...feeSchedule.catCodeList],
            stateList: [...feeSchedule.stateList],
            isActive: !feeSchedule.isActive,
        }
        const patchDocument = createPatch(feeSchedule, newFeeSchedule);
        const patchRequest = yield call(services.api.financials.patchFeeSchedule, action.payload.id, patchDocument); 
        if(patchRequest.isSuccess) {
            const state = yield select();
            const responseList: models.FeeScheduleResponse = state.financials.financials.feeSchedulesResponse; 
            const company = responseList.resourceList.find(x => x.feeScheduleId === action.payload.id)?.insuranceCompany!; 
            yield put(actions.requestFeeSchedulesByCompany(company)); 
        }
        else {
            console.log(patchRequest); 
        }
    }
}