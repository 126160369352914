import { combineReducers } from 'redux';
import { ActionType } from 'typesafe-actions';
import * as models from '../../models';
import * as actions from './actions';
import * as constants from './constants';

export interface FinancialsStateShape {
    connectAdjusterFees: models.ConnectAdjusterFees; 
    feesResponse: models.FeeResponse;
    feeSchedule: models.FeeSchedule; 
    feeSchedulesResponse: models.FeeScheduleResponse; 
    invoice: models.Invoice; 
    invoiceGroupListResponse: models.InvoiceGroupResponse; 
    invoicesListResponse: models.InvoicesListResponse2;     
    invoicesRequest: models.InvoicesRequest;     
    isConnectAdjusterFeesLoading: boolean; 
    isInvoiceLoading: boolean; 
    isInvoiceGroupListLoading: boolean;     
    isInvoiceListLoading: boolean; 
    isInvoicesListDownloading: boolean;  
    isFeeScheduleLoading: boolean;
    isFeeScheduleUpdating: boolean; 
    selectedInvoices: number[]; 
}

export const financialsDefaultState: FinancialsStateShape = {
    connectAdjusterFees: {}, 
    feesResponse: models.defaultResponse, 
    feeSchedule: models.defaultFeeSchedule,
    feeSchedulesResponse: models.defaultResponse,
    invoice: models.defaultInvoice,     
    invoiceGroupListResponse: models.defaultResponse, 
    invoicesListResponse: {
        ...models.defaultResponse,
        bulkInvoices: new Array<models.DropDownListItem>(),
        insuranceCompanies: new Array<models.DropDownListItem>(),
        fieldAdjusters: new Array<models.DropDownListItem>(),
    }, 
    invoicesRequest: models.defaultInvoicesRequest, 
    isConnectAdjusterFeesLoading: false, 
    isInvoiceLoading: false, 
    isInvoiceGroupListLoading: false, 
    isInvoiceListLoading: false, 
    isInvoicesListDownloading: false,     
    isFeeScheduleLoading: false, 
    isFeeScheduleUpdating: false, 
    selectedInvoices: new Array<number>(), 
}

export interface FormFilters {
    invoiceAdjusterDD: models.DropdownListResponse; 
    invoiceCompanyDD: models.DropdownListResponse; 
    invoiceGroupDD: models.DropdownListResponse; 
    isAdjusterDDLoading: boolean; 
    isCompanyDDLoading: boolean; 
    isGroupDDLoading: boolean; 
}

export const FormFilterDefaultState: FormFilters = {
    invoiceAdjusterDD: models.defaultResponse, 
    invoiceCompanyDD: models.defaultResponse, 
    invoiceGroupDD: models.defaultResponse, 
    isAdjusterDDLoading: false,
    isCompanyDDLoading: false, 
    isGroupDDLoading: false, 
}

export interface PaymentsStateShape {
    isReconcileListLoading: boolean; 
    payment: models.Payment; 
    reconcileList: models.InvoicesFullResponse; 
}

export const paymentsDefaultState: PaymentsStateShape = {
    isReconcileListLoading: false, 
    payment: models.defaultPayment,
    reconcileList: models.defaultResponse, 
}

export interface PayrollStateShape {
    adjusterFeeResponse: models.AdjusterFeeResponse; 
    isAdjusterFeesLoading: boolean; 
    isPayrollRunning: boolean; 
    isZipDownloading: boolean; 
}

export const payrollDefaultState: PayrollStateShape = {
    adjusterFeeResponse: {
        ...models.defaultResponse,     
        payrollAdjusters: new Array<models.DropDownListItem>()
    }, 
    isAdjusterFeesLoading: false, 
    isPayrollRunning: false, 
    isZipDownloading: false, 
}

export interface State {
    dropdowns: FormFilters;
    financials: FinancialsStateShape;     
    payments: PaymentsStateShape; 
    payroll: PayrollStateShape; 
}

export type Action = ActionType<typeof actions>;

export default combineReducers<State, Action>({
    financials: (state = financialsDefaultState, action) => {
        switch (action.type) {
            case constants.CLEAR_INVOICE: 
                return {
                    ...state,
                    invoice: models.defaultInvoice
                }; 

            case constants.CLEAR_SELECTED_INVOICES: 
                return {
                    ...state,
                    selectedInvoices: new Array<number>(),
                }; 
            
            case constants.PATCH_FEE_SCHEDULE: 
                return {
                    ...state, 
                    isFeeScheduleUpdating: true, 
                }; 

            case constants.REQUEST_ADJUSTER_FEES: 
                return {
                    ...state, 
                    connectAdjusterFees: {}, 
                    isConnectAdjusterFeesLoading: true, 
                }; 

            case constants.RECEIVE_ADJUSTER_FEES: 
                return {
                    ...state,
                    isConnectAdjusterFeesLoading: false, 
                    connectAdjusterFees: action.payload.response, 
                }; 
            
            case constants.RECEIVE_FEE_LIST: 
                return {
                    ...state, 
                    feesResponse: action.payload.response,
                }; 

            case constants.REQUEST_FEE_LIST: 
                return {
                    ...state,
                }; 

            case constants.RECEIVE_FEE_SCHEDULE_BY_COMPANY: 
                return {
                    ...state, 
                    feeSchedulesResponse: action.payload.response,                    
                }; 

            case constants.REQUEST_FEE_SCHEDULE: 
                return {
                    ...state,
                    isFeeScheduleLoading: true, 
                }; 

            case constants.RECEIVE_FEE_SCHEDULE: 
                return {
                    ...state, 
                    feeSchedule: action.payload.response,
                    isFeeScheduleLoading: false, 
                    isFeeScheduleUpdating: false, 
                }; 

            case constants.REQUEST_FEE_SCHEDULE_BY_COMPANY: 
                return {
                    ...state,
                }; 

            case constants.RECEIVE_INVOICE:
                return {
                    ...state,
                    isInvoiceLoading: false, 
                    invoice: {
                        ...action.payload.response,
                        isTandE: (action.payload.response.hours || 0) > 0
                    }, 
                }; 

            case constants.REQUEST_INVOICE: 
                return {                    
                    ...state,                     
                    isInvoiceLoading: true, 
                }; 

            case constants.RECEIVE_INVOICES_CSV: 
                return {
                    ...state, 
                    isInvoicesListDownloading: false, 
                }; 

            case constants.REQUEST_INVOICES_CSV: 
                return {
                    ...state, 
                    isInvoicesListDownloading: true, 
                }; 

            case constants.RECEIVE_INVOICE_GROUP_LIST: 
                return {
                    ...state,
                    isInvoiceGroupListLoading: false, 
                    invoiceGroupListResponse: action.payload.response
                }; 

            case constants.REQUEST_INVOICE_GROUP_LIST:
                return {
                    ...state,
                    isInvoiceGroupListLoading: true, 
                }; 

            case constants.REQUEST_INVOICE_LIST: 
                return {
                    ...state,                    
                    isInvoiceListLoading: true,
                    invoicesRequest: action.payload.request, 
                    invoicesListResponse: {
                        ...models.defaultResponse,
                        bulkInvoices: new Array<models.DropDownListItem>(), 
                        insuranceCompanies: new Array<models.DropDownListItem>(), 
                        fieldAdjusters: new Array<models.DropDownListItem>(), 
                    }
                }; 

            case constants.RECEIVE_INVOICE_LIST:              
                return {
                    ...state, 
                    invoicesListResponse: action.payload.response, 
                    isInvoiceListLoading: false, 
                }; 

            case constants.SET_CONNECT_FEES_FLAG: 
                return {
                    ...state,
                    isConnectAdjusterFeesLoading: action.payload.request,
                }; 

            case constants.SET_SELECTED_INVOICES: 
                return {
                    ...state, 
                    selectedInvoices: action.payload.invoiceIds, 
                }; 
                
            default: 
                return state; 
        }
    }, 
    payments: (state = paymentsDefaultState, action) => {
        switch (action.type) {
            case constants.CLEAR_RECONCILE_LIST:
                return {
                    ...state, 
                    reconcileList: models.defaultResponse,
                }; 

            case constants.REQUEST_RECONCILE_LIST: 
                return {
                    ...state,                    
                    isReconcileListLoading: true, 
                    reconcileList: models.defaultResponse, 
                }; 
            case constants.RECEIVE_RECONCILE_LIST: 
                return {
                    ...state, 
                    isReconcileListLoading: false, 
                    reconcileList: action.payload.response, 
                }; 
            case constants.REQUEST_RECONCILE_LIST_BULK: 
                return {
                    ...state,
                    isReconcileListLoading: true,
                    reconcileList: models.defaultResponse, 
                }

            default: 
                return state; 
        }
    }, 
    payroll: (state = payrollDefaultState, action) => {
        switch (action.type) {
            case constants.ADD_PAYROLL:
                return {
                    ...state,
                    isPayrollRunning: true, 
                }; 
            case constants.ADD_PAYROLL_COMPLETE:
                return {
                    ...state, 
                    isPayrollRunning: false, 
                }; 

            case constants.CLEAR_ADJUSTER_FEES_LIST: 
                return {
                    ...state, 
                    adjusterFeeResponse: {
                        ...models.defaultResponse,
                        payrollAdjusters: new Array<models.DropDownListItem>(),
                    }
                }; 

            case constants.REQUEST_ADJUSTER_FEES_LIST: 
                return {
                    ...state, 
                    adjusterFeeResponse: {
                        ...models.defaultResponse, 
                        payrollAdjusters: new Array<models.DropDownListItem>(),
                    }, 
                    isAdjusterFeesLoading: true, 
                }; 

            case constants.RECEIVE_ADJUSTER_FEES_LIST: 
                return {
                    ...state, 
                    adjusterFeeResponse: action.payload.response,
                    isAdjusterFeesLoading: false, 
                }

            case constants.REQUSET_PAYROLL_ZIPFILE: 
                return {
                    ...state, 
                    isZipDownloading: true, 
                }

            case constants.RECEIVE_PAYROLL_ZIPFILE: 
                return {
                    ...state,
                    isZipDownloading: false, 
                }

            default: 
                return state; 
        }
    }, 
    dropdowns: (state = FormFilterDefaultState, action) => {
        switch(action.type) {
            case constants.RECEIVE_INVOICE_ADJUSTERS_DD: 
                return {
                    ...state, 
                    invoiceAdjusterDD: action.payload.response,
                    isAdjusterDDLoading: false, 
                }; 

            case constants.REQUEST_INVOICE_ADJUSTERS_DD: 
                return {
                    ...state,
                    isAdjusterDDLoading: true, 
                }; 

                case constants.RECEIVE_INVOICE_COMPANY_DD: 
                return {
                    ...state, 
                    invoiceCompanyDD: action.payload.response,
                    isCompanyDDLoading: false, 
                }; 

            case constants.REQUEST_INVOICE_COMPANY_DD: 
                return {
                    ...state,
                    isCompanyDDLoading: true, 
                }; 

                case constants.RECEIVE_INVOICE_GROUP_DD: 
                return {
                    ...state, 
                    invoiceGroupDD: action.payload.response,
                    isGroupDDLoading: false, 
                }; 

            case constants.REQUEST_INVOICE_GROUP_DD: 
                return {
                    ...state,
                    isGroupDDLoading: true, 
                }; 

            default: 
                return state; 
        }
    }, 
});
