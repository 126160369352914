import * as React from 'react';
import MyTypes from 'MyTypes';
import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Card, Spinner } from 'react-bootstrap'; 
import * as models from '../../models';
import InvoiceSummaryTable from '../financials/InvoiceSummaryTable'; 
import addIcon from '../../assets/images/Blue/add.svg';
import histIcon from '../../assets/images/Blue/history.svg'; 
import SingleActionButton from '../common/SingleActionButton';
import Invoice from '../financials/Invoice'; 
import { claimsActions } from 'store/claims';
import { financialsActions } from 'store/financials';
import { userHasPermission } from '../../utils/helpers';
import InvoiceHistoryModal from 'components/financials/InvoiceHistoryModal';
import DeleteInvoiceModal from 'components/financials/DeleteInvoiceModal';
import { USER_ACTIONS } from 'store/enums'; 
import { userActions } from 'store/user';
import { AppToastTypes } from 'models/enums';

interface Props extends PropsFromRedux {
    invoices: models.InvoicesList[];
    isLoading: boolean;  
}

interface State {
    showModal: boolean; 
    showDeleteModal: boolean; 
    showHistoryModal: boolean; 
    failedValidations: string[];     
    invoiceToDelete: number; 
}

class Invoices extends React.Component<Props, State> {
    public state = {
        showModal: false, 
        showDeleteModal: false, 
        showHistoryModal: false, 
        failedValidations: new Array<string>(), 
        invoiceToDelete: -1, 
    };

    public toggleModal = () => {        
        this.setState({showModal: !this.state.showModal}); 
    } 

    public toggleHistoryModal = () => {        
        this.setState({showHistoryModal: !this.state.showHistoryModal}); 
    } 

    public toggleDeleteModal = () => {
        this.setState({showDeleteModal: !this.state.showDeleteModal}); 
    }

    public handleInvoiceAdd = () => {   
        if(!this.props.claim.adjuster) {
            this.props.setAppToast({message: "Claim must have Adjuster to invoice.", type: AppToastTypes.Failure });
        }
        else {
            this.props.requestDraftInvoice(this.props.claimId); 
            this.props.requestConnectAdjusterFees(this.props.claim.adjuster.id);  
            this.toggleModal(); 
        }
    };     

    public handleHistory = () => {
        this.toggleHistoryModal(); 
    }

    public deleteInvoice = (rowId: string) => {
        const invoice = this.props.invoices.find(x => x.invoiceId === parseInt(rowId)); 
        if(invoice && (invoice.totalPayments > 0 || invoice.totalPayroll > 0))
            alert("Cannot delete invoice once payment has been received or payouts to adjusters have occured."); 
        else
        {
            this.setState({showDeleteModal: true, invoiceToDelete: parseInt(rowId)}); 
        }            
    }

    public editInvoice = (rowId: string) => {
        const item: models.InvoicesList | undefined = this.props.invoices.find(x => x.invoiceId === parseInt(rowId)); 
        if (item)
            this.props.requestInvoice(parseInt(rowId)); 
        this.toggleModal(); 
    }    

    public buildPDF = (rowId: string) => {
        if(!this.props.claim.insuredPerson){
            this.props.setAppToast({message: "Claim must have an Insured Person to invoice.", type: AppToastTypes.Failure}); 
            return; 
        }
        if(!this.props.claim.adjuster) {
            this.props.setAppToast({message: "Claim must have a Field Adjuster to invoice.", type: AppToastTypes.Failure}); 
            return; 
        }
        if(!this.props.claim.insuranceCompany) {
            this.props.setAppToast({message: "Claim must have an Insurance Company to invoice.", type: AppToastTypes.Failure}); 
            return; 
        }
        this.props.buildPDF(parseInt(rowId));         
    }

    public getButtonActions = (invoice: models.InvoicesList) => {
        const canEditInvoice = userHasPermission("Can Edit Invoice"); 
        const canEditPDFdInvoice = invoice.invoicePDF == null || userHasPermission("Can Edit PDFd Invoices"); 
        const isClaimClosed = this.props.claim.status.toLocaleLowerCase() === 'closed'; 

        let buttonActions: models.ButtonAction[] = [
            {
                name: canEditInvoice && canEditPDFdInvoice && !isClaimClosed ? 'Edit' : 'View', 
                callBack: this.editInvoice
            }
        ]; 
        if (userHasPermission("Can approve/finalize invoice")) {
            buttonActions = buttonActions.concat({
                name: 'Build PDF', 
                callBack: this.buildPDF
            }); 
        }
        if (userHasPermission("Can delete/zero out invoice")) {
            buttonActions = buttonActions.concat({
                name: 'Delete', 
                callBack: this.deleteInvoice
            }); 
        }
        return buttonActions; 
    }

    public render() {
        const { claim, invoices, isLoading, lastActionStatus  } = this.props;
        const { invoiceToDelete, showModal, showDeleteModal, showHistoryModal } = this.state; 
        const canCreate = userHasPermission("Can create invoices") && claim.status.toLowerCase() !== 'closed'; ; 
        const isPDFBuilding = lastActionStatus === USER_ACTIONS.INVOICE_BUILDING_PDF; 

        const runSpinner = isLoading || isPDFBuilding; 

        return (
            <>
            <DeleteInvoiceModal
                closeModal={this.toggleDeleteModal}
                showModal={showDeleteModal}     
                invoiceId={invoiceToDelete}
            />
            <Invoice 
                closeModal={this.toggleModal}
                showModal={showModal}                
            />
            <InvoiceHistoryModal
                claimId={this.props.claim.claimID}
                claimNumber={this.props.claim.insClaimNumber}
                closeModal={this.toggleHistoryModal}
                showModal={showHistoryModal}
            />
            <Card className="claim-card">
                <Card.Title>Invoices
                    {runSpinner && <span>&nbsp;&nbsp;<Spinner animation="border" role="status" variant="primary" size="sm"/></span>}                    
                    {canCreate &&
                        <SingleActionButton
                            action="Add"
                            title="Invoice"
                            img={addIcon}
                            onClick={this.handleInvoiceAdd}
                        />
                    }
                    {userHasPermission("View Invoice History") &&
                        <SingleActionButton
                                action="Show"
                                title="History"
                                img={histIcon}
                                onClick={this.handleHistory}
                            />
                    }
                </Card.Title>
                {invoices.length > 0 &&
                    <InvoiceSummaryTable
                        buttonActions={this.getButtonActions}
                        invoices={invoices}
                    />
                }
            </Card>
            </>
        );
    }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
    claimId: state.claims.claim.claim.claimID, 
    claim: state.claims.claim.claim, 
    lastActionStatus: state.claims.claim.lastActionStatus,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {            
            buildPDF: financialsActions.buildPDF, 
            requestConnectAdjusterFees: financialsActions.requestAdjusterFees, 
            requestDraftInvoice: claimsActions.requestInvoiceDraft,
            requestInvoice: financialsActions.requestInvoice,
            setAppToast: userActions.setAppToast,  
        },
        dispatch
    );

const connector = connect(mapStateToProps, mapDispatchToProps); 
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(Invoices);
