export enum ApplicationSection {    
    ADJ = "Adjuster",
    CLR = "Claim Report",
    EST = "Estimate", 
    ICD = "Insurance Company Documents",
    INV = "Invoice",
    LNS = "Loss Notification",
    MGR = "Merged Report",
    NON = "None",
}

export enum CLAIM_ACTION_STATUSES {
    DELETING_ATTACHMENT = 'DELETING_ATTACHMENT', 
    DELETE_ATTACHMENT_SUCCESS = 'DELETE_ATTACHMENT_SUCCESS', 
    DELETE_ATTACHMENT_FAILED = 'DELETE_ATTACHMENT_FAILED',    
    UPDATING_PROFILE = 'UPDATING_PROFILE', 
    UPDATING_PROFILE_SUCCESS = 'UPDATING_PROFILE_SUCCESS',
    UPDATING_PROFILE_FAILURE = 'UPDATING_PROFILE_FAILURE', 
}

// moving claim action statuses over to user actions...
export enum USER_ACTIONS {
    INIT = 'INIT',
    CLAIM_CREATING = 'CLAIM_CREATING', 
    CLAIM_CREATING_FAILURE = 'CLAIM_CREATING_FAILURE',
    CLAIM_CREATING_SUCCESS = 'CLAIM_CREATING_SUCCESS',     
    CLAIM_CREATING_INVOICE = 'CLAIM_CREATING_INVOICE',
    CLAIM_CREATE_INVOICE_FAILURE = 'CLAIM_CREATE_INVOICE_FAILURE', 
    CLAIM_CREATE_INVOICE_SUCCESS = 'CLAIM_CREATE_INVOICE_SUCCESS',
    CLAIM_UPDATING = 'CLAIM_UPDATING', 
    CLAIM_UPDATE_FAILURE = 'CLAIM_UPDATE_FAILURE', 
    CLAIM_UPDATE_SUCCESS = 'CLAIM_UPDATE_SUCCESS', 
    INVOICE_BUILDING_PDF = 'INVOICE_BUILDING_PDF',
    INVOICE_BUILD_PDF_SUCCESS = 'INVOICE_BUILD_PDF_SUCCESS',
    INVOICE_BUILD_PDF_FAILURE = 'INVOICE_BUILD_PDF_FAILURE', 
    INVOICE_SAVING = 'INVOICE_SAVING', 
    INVOICE_SAVING_FAILURE = 'INVOICE_SAVING_FAILURE', 
    INVOICE_SAVING_SUCCESS = 'INVOICE_SAVING_SUCCESS',
    INVOICE_DELETING = 'INVOICE_DELETING', 
    INVOICE_DELETE_FAILURE = 'INVOICE_DELETE_FAILURE', 
    INVOICE_DELETE_SUCCESS = 'INVOICE_DELETE_SUCCESS', 
}

export enum USER_PERMISSIONS {
    CAN_EDIT_INVOICE_FEE_TAX_STATUS = 'Invoicing - Can update tax flag'
}
