import * as React from 'react';
import MyTypes from 'MyTypes';
import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Card, Col, Modal, Button, Form, Table } from 'react-bootstrap'; 
import FeeScheduleBuilder from './FeeScheduleBuilder';
import Invoice from './Invoice';
import { financialsActions } from 'store/financials';
import _, { at } from 'lodash'; 
import NumberFormat from 'react-number-format'; 
import dateFormat from 'dateformat';
import * as models from '../../models';
import services from 'services';
import utils from '../../utils';
import { userActions } from 'store/user';
import { AppToastTypes } from 'models/enums';
import App from 'components/app/App';
import Feedback from 'react-bootstrap/esm/Feedback';

interface Props extends PropsFromRedux {
    claimId: number; 
    claimNumber: string;     
    showModal: boolean;    
    closeModal: () => void;
}

interface State {
    feeScheduleId: number, 
    invoiceEvents: models.InvoiceEvent[];
    invoiceEventsResponse: models.ResponseBase<models.InvoiceEvent>; 
    selectedInvoice: string; 
    showInvoice: boolean; 
    showFSBModal: boolean; 
    zeroChangePhrase: string; 
}

interface Diff {fieldName: string, oldValue: string, newValue: string}; 
interface FeeDiff {operation: string, oldValue: models.InvoiceFee, newValue: models.InvoiceFee}; 

const UsDollar = new Intl.NumberFormat('en-US', {
    style: 'currency', 
    currency: 'USD', 
});  
class InvoiceHistoryModal extends React.Component<Props, State> {
    public state = {
        feeScheduleId: 0, 
        invoiceEvents: new Array<models.InvoiceEvent>,
        invoiceEventsResponse: {
            totalCount: 0,
            resourceList: new Array<models.InvoiceEvent>,
            error: {code: 0, message: ''}
        },
        selectedInvoice: "",
        showInvoice: false,  
        showFSBModal: false, 
        zeroChangePhrase: 'No changes detected'
    };

    componentDidUpdate(prevProps: any) {     
        if (!_.isEqual(prevProps.showModal, this.props.showModal) && this.props.showModal) {
            this.getFormData(); 
        }
    }
    
    public getFormData = async () => {
        this.getInvoiceHistory(); 
    }    

    public getInvoiceHistory = async () => {
        const response = await services.api.financials.getInvoiceEventsByClaim(this.props.claimId); 

        if (response.isSuccess){
            const events:models.ResponseBase<models.InvoiceEvent> = response.data; 
            this.setState({invoiceEventsResponse: events, invoiceEvents: events.resourceList});
        }
    }

    public resetFormStateAndClose = () => {
        this.props.closeModal(); 
    }

    public submitForm = (e:any) => {
        e.preventDefault();
        this.resetFormStateAndClose(); 
    }

    public handleSelectInvoice = (e:any) => {        
        let newInvoiceEventList: models.InvoiceEvent[] = new Array<models.InvoiceEvent>;

        if(e.target.value === "All"){
            newInvoiceEventList = this.state.invoiceEventsResponse.resourceList; 
        }
        else {
            newInvoiceEventList = this.state.invoiceEventsResponse.resourceList.filter(x => x.invoiceNumber === e.target.value); 
        }
        this.setState({selectedInvoice: e.target.value, invoiceEvents: newInvoiceEventList}); 
    }

    public formatDiff = (d: Diff) => {        
        switch(d.fieldName.toLocaleLowerCase()) {
            case 'grossloss': 
                return 'Gross Loss' + ' changed from ' 
                    + UsDollar.format(parseFloat(d.oldValue)) + ' to ' 
                    + UsDollar.format(parseFloat(d.newValue)); 
            default: 
                return d.fieldName + ' changed from ' + d.oldValue + ' to ' + d.newValue;  
        }
    }

    public formatFeeDiff = (d: FeeDiff) => {     
        switch(d.operation.toLocaleLowerCase()){
            case 'fee added':                
                return d.newValue.feeName + ' fee added:'
                    + ' Qty: ' + d.newValue.quantity.toString() + ';'
                    + ' Rate: ' + UsDollar.format(d.newValue.rate) + ';'
                    + ' Total: ' + UsDollar.format(d.newValue.total) + ';'
                    + ' Adj %: ' + d.newValue.adjusterPercent.toFixed(0).toString()
                    + '\n'; 
            case 'fee removed':
                return d.oldValue.feeName + ' fee removed:'
                    + ' Qty: ' + d.oldValue.quantity.toString() + ';'
                    + ' Rate: ' + UsDollar.format(d.oldValue.rate) + ';'
                    + ' Total: ' + UsDollar.format(d.oldValue.total)
                    + '\n'; 
            case 'fee changed':
                const qtyChanged = d.newValue.quantity !== d.oldValue.quantity; 
                const rateChanged = d.newValue.rate !== d.oldValue.rate; 
                const adjPerChanged = d.newValue.adjusterPercent !== d.oldValue.adjusterPercent; 
                const commentChanged = d.newValue.comment !== d.oldValue.comment; 
                const changesDetected = [qtyChanged,rateChanged,adjPerChanged,commentChanged].filter(Boolean).length;  
                const changeText = changesDetected > 1 ? 'changes' : 'change'; 

                let outputString = ''; 
                if(changesDetected === 0)
                    return null; 
                
                outputString += d.newValue.feeName + ` fee updated (${changesDetected.toString()} ${changeText} found):`; 
                outputString += !qtyChanged ? '' : `\n\tQty changed from ${d.oldValue.quantity} to ${d.newValue.quantity}`; 
                outputString += !rateChanged ? '' :  `\n\tRate changed from ${d.oldValue.rate} to ${d.newValue.rate}`; 
                outputString += !adjPerChanged ? '' : `\n\tAdj % changed from ${d.oldValue.adjusterPercent} to ${d.newValue.adjusterPercent}`;  
                if (commentChanged){
                    if(d.oldValue.comment == null)
                        outputString += !commentChanged ? '' : `\n\tComment added ${d.newValue.comment}`;  
                    else 
                        outputString += !commentChanged ? '' : `\n\tComment changed from ${d.oldValue.comment} to ${d.newValue.comment}`;  
                }                    
                return outputString + '\n'; 
                                
            default: 
                return null; 
        }        
    }

    public getEventDetails = (id: number, type: string, details: any) => {
        switch (type.toLocaleLowerCase()) {
            case 'created': 
                const invoice: models.Invoice = details; 
                if(!invoice)
                    return <td></td>
                return <td style={{whiteSpace: "pre"}} >{'Gross Loss: ' + UsDollar.format(invoice.grossLoss)}</td>
            case 'deleted': 
                return <td
                            style={{cursor:"pointer", whiteSpace:"pre-line"}} 
                            className='value-link' 
                            onClick={() => this.viewInvoice(id)}
                            >{details}
                        </td>; 
            case 'edited':         
                const diffs: Diff[] = details; 
                if(!diffs)
                    return <td>{this.state.zeroChangePhrase}</td>
                return <td style={{whiteSpace: "pre"}} >{diffs.map((d) => (this.formatDiff(d))).join('\n')}</td>
            case 'fees changed':                                          
                const feeDiffs: FeeDiff[] = details; 
                if(!feeDiffs)
                    return <td>{this.state.zeroChangePhrase}</td>
                const text = feeDiffs.map((d) => (this.formatFeeDiff(d))).filter(x => x !== null);
                if(text.length === 0) return <td>{this.state.zeroChangePhrase}</td>                
                return <td style={{whiteSpace: "pre"}} >{text}</td>
            case 'fee schedule applied':
                const fsEvent: {id:number, name: string} = details;
                return <td 
                            style={{cursor:"pointer", whiteSpace:"pre-line"}} 
                            className='value-link' 
                            onClick={() => this.toggleFSBModal(fsEvent.id)}
                            >{fsEvent.name}
                        </td>; 
            case 'pdf created':
                const pdfEvent: {id:number, name: string} = details;                                
                if(!pdfEvent) return <td></td>
                return <td
                            style={{cursor:"pointer"}} 
                            className='value-link' 
                            onClick={() => this.downloadFile(id, pdfEvent.id)}
                            >{pdfEvent.name}
                        </td>
            case 'payment received':
                return <td>{details}</td>; 
            default: 
                return <td></td>
        }        
    }

    public toggleFSBModal = (feeSchduleId?: number) => {
        this.setState({feeScheduleId: feeSchduleId || 0, showFSBModal: !this.state.showFSBModal}); 
    }

    public toggleInvoice = () => {
        this.setState({showInvoice: !this.state.showInvoice}); 
    }

    public viewInvoice = (id: number) => {
        this.props.requestInvoice(id); 
        this.toggleInvoice(); 
    }

    public downloadFile = async (id: number, attachmentId: number) => {
        const response = await services.api.financials.getInvoiceAttachment2(id, attachmentId);        
        if(response.isError) {
            this.props.setAppToast({message: "Error retrieving file", type: AppToastTypes.Failure}); 
        }
        else {            
            utils.filehelpers.openFileNewTab(response.data); 
        }
    }
 
    public render() {
        const { claim, closeModal, showModal } = this.props;
        const { feeScheduleId, invoiceEvents, invoiceEventsResponse, selectedInvoice, showFSBModal, showInvoice } = this.state; 

        const invoiceList = [... new Set(invoiceEventsResponse.resourceList.map(x => x.invoiceNumber))];      

        return (
            <>
            <Invoice 
                closeModal={this.toggleInvoice}
                showModal={showInvoice}                
            />
            <FeeScheduleBuilder
                company={claim.insuranceCompany}
                feeScheduleId={feeScheduleId}
                showModal={showFSBModal}
                closeModal={this.toggleFSBModal}
            /> 
            <Modal size='xl' show={showModal} onHide={closeModal} >                                
                <Modal.Header closeButton>
                    <Modal.Title>Invoice History</Modal.Title>
                </Modal.Header>
                    <Modal.Body>
                        <Form className="claim-modal">
                            <Form.Group controlId="invoices" className="input-field-container2">
                                <Col sm={{span: 2}}>
                                    <Form.Label>Invoice #:</Form.Label>
                                </Col>
                                <Col sm={{span:4}}>
                                    <Form.Select name="invoices" 
                                        value={selectedInvoice} 
                                        onChange={(e) => this.handleSelectInvoice(e)}
                                        >
                                            {<option key={-1}>All</option>}                                        
                                            {invoiceList.sort().map((f: string, i: number) => ( 
                                                <option key={i} value={f} >{f}</option>
                                            ))}
                                    </Form.Select>
                                </Col>
                            </Form.Group>   
                        </Form>                        
                        <Card className="claim-card">
                            <Card.Title>Invoice Events</Card.Title>
                            <Card.Body style={{maxHeight: "500px", overflow: "auto" }}>
                                <Table striped>
                                    <thead>
                                        <tr>
                                            <th>Invoice #</th>
                                            <th>User</th>
                                            <th>Date</th>
                                            <th>Event</th>
                                            <th>Total</th>
                                            <th>Payment</th>
                                            <th>Total Paid</th>
                                            <th>Balance</th>
                                            <th>Details</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {invoiceEvents && invoiceEvents.map((x: models.InvoiceEvent, i: number) => (
                                        <tr key={i}>
                                            <td>{x.invoiceNumber}</td>
                                            <td>{x.actor}</td>
                                            <td>{dateFormat(x.eventDate, "paddedShortDate")}</td>
                                            <td>{x.eventName}</td>
                                            <td><NumberFormat value={x.grandTotal.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /> </td>
                                            <td><NumberFormat value={x.paymentAmount.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /> </td>
                                            <td><NumberFormat value={x.paymentsReceived.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /> </td>
                                            <td><NumberFormat value={x.outstandingBalance.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /> </td>
                                            {this.getEventDetails(x.invoiceId, x.eventName, x.eventDetails)}
                                        </tr>
                                    ))}
                                    </tbody>
                                </Table>
                            </Card.Body>
                        </Card>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.resetFormStateAndClose}>
                            EXIT
                        </Button>
                    </Modal.Footer>
            </Modal>  
            </>          
        );
    }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
    claim: state.claims.claim.claim, 
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {         
            requestInvoice: financialsActions.requestInvoice,
            setAppToast: userActions.setAppToast, 
        },
        dispatch
    );

const connector = connect(mapStateToProps, mapDispatchToProps); 
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(InvoiceHistoryModal);