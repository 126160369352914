import MyTypes from 'MyTypes';
import * as React from 'react';
import { Form, Image } from 'react-bootstrap'; 
import { connect, ConnectedProps } from 'react-redux';
import serivces from '../../services/'; 
import { bindActionCreators, Dispatch } from 'redux';
import searchIcon from '../../assets/images/Blue/icn-search-blue.svg'; 
import * as models from '../../models/'; 
import { claimsActions } from 'store/claims';

interface Props extends PropsFromRedux {  
}

interface State {
    claimNumber: string; 
}

class QuickSearch extends React.Component<Props, State> {
    public state ={
        claimNumber: '', 
    }

    public claimSearch = (e: any) => {
        const claimQuery : models.ClaimsRequest = {
            ...models.defaultClaimsRequest,
            pageNo: 1,
            pageSize: 2,
            insClaimNo: e.target.quickSearch.value,
        }
        this.props.requestClaims(claimQuery);        
        this.setState({claimNumber: ""}); 
        e.preventDefault();
    }

    public componentDidUpdate = () => {        
        if(this.props.claimListResponse.totalCount == 1){
            const claimID = this.props.claimListResponse.resourceList[0].claimID; 
            this.props.requestClaimSingle(claimID); 
            let path = "/claim/" + claimID;            
            serivces.router.goto(path); 
        }
        else {
            // add more handling...
            console.log("Quicksearch found zero or multiple results..."); 
        }
    }
  
    public render() {
        const { claimNumber } = this.state; 
        const { searchLoading } = this.props; 

        return (

            <div className="quick-search">                
                <Form 
                    onSubmit={this.claimSearch}>
                    <Form.Control 
                        name="quickSearch"
                        placeholder="Quick Search..." 
                        value = {searchLoading ? "Loading..." : claimNumber}
                        onChange={(e) => this.setState({claimNumber: e.target.value})}
                />
                </Form>
                <Image src={searchIcon} className="icon"/>
            </div>
        );
    }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
    claimListResponse: state.claims.search.claimsListResponse,
    searchLoading : state.claims.search.isClaimsListLoading,  
}); 

const mapDispatchToProps = (dispatch: Dispatch) => 
    bindActionCreators(
        {
            requestClaims: claimsActions.requestClaims,
            requestClaimSingle: claimsActions.requestClaimSingle,
        },
        dispatch
    );

const connector = connect(mapStateToProps, mapDispatchToProps); 
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(QuickSearch); 
