import { action } from 'typesafe-actions';
import * as constants from './constants';
import * as models from '../../models';

export const requestZipSearch = (zipCode: string) =>
    action(constants.REQUEST_ZIPSEARCH, { zipCode }); 

export const receiveZipSearch = (response: models.ZipSearch) =>
    action(constants.RECEIVE_ZIPSEARCH, { response }); 


// dropdowns
export const requestStateAbbrDD = () => 
    action(constants.REQUEST_STATE_ABBR_DD);
export const receiveStateAbbrDD = (response: models.DropdownListResponse) =>
    action(constants.RECEIVE_STATE_ABBR_DD, { response });

export const requestStateDD = () => 
    action(constants.REQUEST_STATE_DD);
export const receiveStateDD = (response: models.DropdownListResponse) =>
    action(constants.RECEIVE_STATE_DD, { response });