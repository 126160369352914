import { action } from 'typesafe-actions';
import * as constants from './constants';
import * as models from '../../models';

export const addFee = (fee: models.Fee) => 
    action(constants.ADD_FEE, { fee }); 

export const addFeeToSchedule = (feeScheduleId: number, fee: models.Fee) =>
    action(constants.ADD_FEE_TO_SCHEDULE, { feeScheduleId, fee }); 

export const addInvoiceGroup = (invoiceGroup: models.InvoiceGroup) =>
    action(constants.ADD_INVOICE_GROUP, { invoiceGroup }); 

export const addInvoiceToGroup = (invoiceId: number, groupName: string) =>
    action(constants.ADD_INVOICE_TO_GROUP, { invoiceId, groupName }); 

export const buildPDF = (invoiceId: number) =>
    action(constants.BUILD_PDF, { invoiceId }); 

export const clearSelectedInvoices = () =>
    action(constants.CLEAR_SELECTED_INVOICES); 

export const deleteFee = (id: number) =>
    action(constants.DELETE_FEE, { id }); 

export const patchFee = (feeId: any, patch: object) =>
    action(constants.PATCH_FEE, {feeId, patch}); 

export const patchInvioce = (invoiceId: number, patch: object) =>
    action(constants.PATCH_INVOICE, {invoiceId, patch}); 

export const replaceInvoice = (invoiceId: number, invoice: models.Invoice) =>
    action(constants.REPLACE_INVOICE, { invoiceId, invoice }); 

export const requestAdjusterFees = (adjusterId: number) =>
    action(constants.REQUEST_ADJUSTER_FEES, { adjusterId }); 
export const receiveAdjusterFees = (response: models.ConnectAdjusterFees) =>
    action(constants.RECEIVE_ADJUSTER_FEES, { response }); 

export const removeInvoiceFromGroup = (invoiceId: number) =>
    action(constants.ADD_INVOICE_TO_GROUP, { invoiceId }); 

export const requestFeesList = () => 
    action(constants.REQUEST_FEE_LIST); 

export const receiveFeesList = (response: models.FeeResponse) => 
    action(constants.RECEIVE_FEE_LIST, { response }); 

export const clearInvoice = () => 
    action(constants.CLEAR_INVOICE); 

export const requestInvoice = (invoiceId: number) =>
    action(constants.REQUEST_INVOICE, { invoiceId }); 

export const receiveInvoice = (response: models.Invoice) =>
    action(constants.RECEIVE_INVOICE, { response }); 

export const requestInvoiceAdjsutersDD = (request: models.InvoicesRequest) =>
    action(constants.REQUEST_INVOICE_ADJUSTERS_DD, { request }); 

export const receiveInvoiceAdjsutersDD = (response: models.DropdownListResponse) =>
    action(constants.RECEIVE_INVOICE_ADJUSTERS_DD, { response }); 

export const requestInvoicesCSV = (request: models.InvoicesRequest) =>
    action(constants.REQUEST_INVOICES_CSV, { request });     

export const receiveInvoicesCSV = () =>
    action(constants.RECEIVE_INVOICES_CSV);

export const requestInvoiceList = (request: models.InvoicesRequest) =>
    action(constants.REQUEST_INVOICE_LIST, { request }); 

export const receiveInvoiceList = (response: models.InvoicesListResponse2) =>
    action(constants.RECEIVE_INVOICE_LIST, { response }); 

export const requestInvoiceAdjusterDD = (request: models.InvoicesRequest) =>
    action(constants.REQUEST_INVOICE_ADJUSTERS_DD, { request }); 

export const receiveInvoiceAdjusterDD = (response: models.DropdownListResponse) =>
    action(constants.RECEIVE_INVOICE_ADJUSTERS_DD, { response }); 

export const requestInvoiceCompanyDD = (request: models.InvoicesRequest) =>
    action(constants.REQUEST_INVOICE_COMPANY_DD, { request }); 

export const receiveInvoiceCompanyDD = (response: models.DropdownListResponse) =>
    action(constants.RECEIVE_INVOICE_COMPANY_DD, { response }); 

export const requestInvoiceGroupsList = (request: models.InvoiceGroupsRequest) => 
    action(constants.REQUEST_INVOICE_GROUP_LIST, { request }); 

export const receiveInvoiceGroupsList = (response: models.InvoiceGroupResponse) =>
    action(constants.RECEIVE_INVOICE_GROUP_LIST, { response }); 

export const requestInvoiceGroupDD = (request: models.InvoicesRequest) =>
    action(constants.REQUEST_INVOICE_GROUP_DD, { request }); 

export const receiveInvoiceGroupDD = (response: models.DropdownListResponse) =>
    action(constants.RECEIVE_INVOICE_GROUP_DD, { response }); 

export const setConnectFeesFlag = (request: boolean) =>
    action(constants.SET_CONNECT_FEES_FLAG, { request }); 

export const setSelectedIinvoices = (invoiceIds: number[]) =>
    action(constants.SET_SELECTED_INVOICES, { invoiceIds }); 

// fee schedules
export const createFeeSchedule = (request: models.FeeSchedule) => 
    action(constants.CREATE_FEE_SCHEDULE, { request }); 

export const deleteFeeSchedule = (id: number) =>
    action(constants.DELETE_FEE_SCHEDULE, { id }); 

export const patchFeeSchedule = (id: number, patch: object) =>
    action(constants.PATCH_FEE_SCHEDULE, { id, patch }); 

export const requestFeeSchedule = (feeScheduleId: number) => 
    action(constants.REQUEST_FEE_SCHEDULE, { feeScheduleId }); 

export const receiveFeeSchedule = (response: models.FeeSchedule) =>
    action(constants.RECEIVE_FEE_SCHEDULE, { response }); 

export const requestFeeSchedulesByCompany = (company: string) =>
    action(constants.REQUEST_FEE_SCHEDULE_BY_COMPANY, { company }); 

export const receiveFeeSchedulesByCompany = (response: models.FeeScheduleResponse) =>
    action(constants.RECEIVE_FEE_SCHEDULE_BY_COMPANY, { response }); 

export const toggleFeeScheduleStatus = (id: number) =>
    action(constants.TOGGLE_FEE_SCHEDULE_STATUS, { id }); 

// payments
export const clearReconcileList = () => 
    action(constants.CLEAR_RECONCILE_LIST); 

export const createPayment = (request: models.AddPayment) =>
    action(constants.CREATE_PAYMENT, { request }); 

export const requestReconcileList = (request: number[]) =>
    action(constants.REQUEST_RECONCILE_LIST, { request }); 

export const receiveReconcileList = (response: models.InvoicesFullResponse) =>
    action(constants.RECEIVE_RECONCILE_LIST, { response }); 

export const requestReconcileListBulk = (request: string) =>
    action(constants.REQUEST_RECONCILE_LIST_BULK, { request }); 

// payroll
export const addPayroll = (request: models.AddPayroll) =>
    action(constants.ADD_PAYROLL, { request }); 
export const addPayrollComplete = () =>
    action(constants.ADD_PAYROLL_COMPLETE); 
    
export const clearAdjusterFeesList = () =>
    action(constants.CLEAR_ADJUSTER_FEES_LIST); 
    
export const requestAdjusterFeesList = (request: models.AdjusterFeeRequest) =>
    action(constants.REQUEST_ADJUSTER_FEES_LIST, { request }); 

export const receiveAdjusterFeesList = (response: models.AdjusterFeeResponse) =>
    action(constants.RECEIVE_ADJUSTER_FEES_LIST, { response }); 

export const requestPayrollZipFile = (payrollId: number) =>
    action(constants.REQUSET_PAYROLL_ZIPFILE, { payrollId }); 

export const receivePayrollZipFile = () =>
    action(constants.RECEIVE_PAYROLL_ZIPFILE); 