import * as models from '..';
const now = new Date(); 

export interface Payment {
    paymentId: number; 
    paymentInfo: string;     
    comments: string; 
    amount: number; 
    paymentDate: string; 
    fees: {id: number, amt: number}[]; 
}

export const defaultPayment: Payment = { 
    paymentId: 0,    
    paymentInfo: '', 
    comments: '', 
    amount: 0, 
    paymentDate: now.toISOString().split('T')[0], 
    fees: [],
}