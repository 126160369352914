import MyTypes from 'MyTypes';
import * as React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect, ConnectedProps } from 'react-redux';
import { Link } from 'react-router-dom';
import { Image, Table, Card } from 'react-bootstrap';
import * as models from '../../models';
import dateFormat from 'dateformat';
import SingleActionButton from 'components/common/SingleActionButton'; 
import downloadIcon from '../../assets/images/Blue/download.svg';
import sortDesc from '../../assets/images/sort-up.svg'; 
import sortAsc from '../../assets/images/sort-down-alt.svg'; 
import { claimsActions } from 'store/claims';

interface Props extends PropsFromRedux {
    title: string; 
    isSortable: boolean; 
}

interface State {
    sortField: string,
    isDesc: boolean,

}
class ClaimList extends React.Component<Props, State> {
    public state = {
        sortField: this.props.claimsRequest.sort,
        isDesc: true, 
    };
    
    public sortTable(e: any) {        
        const currentSortField = this.props.claimsRequest.sort.replace("-",""); 
        const newSortField = e.target.id;         
        

        const isFieldChanged = !(newSortField == currentSortField); 
        let isDesc = this.state.isDesc ; 
        if (isFieldChanged) {
            isDesc = (isFieldChanged ? !this.state.isDesc : false); 
            this.setState({sortField: newSortField, isDesc: isDesc});
        }
        else {
            console.log("Sort Changed..."); 
            isDesc = !isDesc; 
            this.setState({isDesc: isDesc});
        }

        const claimsRequest: models.ClaimsRequest = {
            ...this.props.claimsRequest, 
            sort: (isDesc ? "-" : "") + (isFieldChanged ? newSortField : currentSortField)
        }
        this.props.requestClaims(claimsRequest); 
    }

    public exportClaims = async () => {
        const exportQuery: models.ClaimsRequest = {
            ...this.props.claimsRequest,
            pageNo: 1,
            pageSize: this.props.claimsListResponse.totalCount,
        }
        await this.props.requestClaimCSV(exportQuery); 
    }

    public render() {
        const { claimsListResponse, isClaimsListDownloading, title, isLoading, isSortable } = this.props;
        const sortField = this.props.claimsRequest.sort.replace("-", ""); 
        const { isDesc } = this.state;        

        return (
            <Card className="claim-card">                
                {isClaimsListDownloading && 
                    <span>Downloading...</span> 
                }
                <Card.Title>{title}                                                       
                    <SingleActionButton
                            action="Export"
                            title="Claims"
                            img={downloadIcon}
                            onClick={this.exportClaims }
                            disabled={isClaimsListDownloading}
                        />
                </Card.Title>
                {isLoading 
                    && <span>Loading...</span> 
                }   
                {claimsListResponse?.totalCount > 0 &&
                    <Table striped>
                        <thead>
                            <tr 
                                className="claim-table-header"
                                onClick={this.sortTable.bind(this)}
                                >
                                <th id="claimNumber">Claim Number
                                    {isSortable && sortField ==="claimNumber" && <Image src={isDesc ? sortDesc : sortAsc}/>}
                                </th>
                                <th id="status">Status
                                    {isSortable && sortField ==="status" && <Image src={isDesc ? sortDesc : sortAsc}/>}
                                </th>
                                <th id="insCompany">Ins Company
                                    {isSortable && sortField ==="insCompany" && <Image src={isDesc ? sortDesc : sortAsc}/>}
                                </th>
                                <th id="insuredName">Insured
                                    {isSortable && sortField ==="insuredName" && <Image src={isDesc ? sortDesc : sortAsc}/>}
                                </th>
                                <th id="examinerName">Examiner
                                    {isSortable && sortField ==="examinerName" && <Image src={isDesc ? sortDesc : sortAsc}/>}
                                </th>
                                <th id="adjusterName">Adjuster
                                    {isSortable && sortField ==="adjusterName" && <Image src={isDesc ? sortDesc : sortAsc}/>}
                                </th>
                                <th id="dateLoss">Loss
                                    {isSortable && sortField ==="dateLoss" && <Image src={isDesc ? sortDesc : sortAsc}/>}
                                </th>
                                <th id="dateReported">Reported
                                    {isSortable && sortField ==="dateReported" && <Image src={isDesc ? sortDesc : sortAsc}/>}
                                </th>
                                <th id="dateReceived">Received
                                    {isSortable && sortField ==="dateReceived" && <Image src={isDesc ? sortDesc : sortAsc}/>}
                                </th>
                                <th id="dateReopened">Re-Opened
                                    {isSortable && sortField ==="dateReopened" && <Image src={isDesc ? sortDesc : sortAsc}/>}
                                </th>
                                <th id="dateClosed">Closed
                                    {isSortable && sortField ==="dateClosed" && <Image src={isDesc ? sortDesc : sortAsc}/>}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {claimsListResponse?.resourceList?.map((claim: models.ClaimsList, i: number) => (
                                <tr key={claim.claimID}>
                                    <td><Link to={"/claim/" + claim.claimID}>{claim.insClaimNumber}</Link></td>
                                    <td>{claim.status}</td>
                                    <td>{claim.insuranceCompany}</td>
                                    <td>{claim.insuredPerson}</td>
                                    <td>{claim.examiner?.name}</td>
                                    <td>{claim.adjuster?.name}</td>
                                    <td>{claim.dateLoss && dateFormat(claim.dateLoss, 'mm/dd/yyyy')}</td>
                                    <td>{claim.dateReported && dateFormat(claim.dateReported, 'mm/dd/yyyy')}</td>
                                    <td>{claim.dateReceived && dateFormat(claim.dateReceived, 'mm/dd/yyyy')}</td>
                                    <td>{claim.dateReopened && dateFormat(claim.dateReopened, 'mm/dd/yyyy')}</td>
                                    <td>{claim.dateClosed && dateFormat(claim.dateClosed, 'mm/dd/yyyy')}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                }                
            </Card>
        );
    }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
    claimsListResponse: state.claims.search.claimsListResponse,
    isLoading: state.claims.search.isClaimsListLoading,
    claimsRequest: state.claims.search.claimsRequest, 
    isClaimsListDownloading: state.claims.search.isClaimsListDownloading, 
});

const mapDispatchToProps = (dispatch: Dispatch) => 
    bindActionCreators(
        {
            requestClaims: claimsActions.requestClaims,
            requestClaimCSV: claimsActions.requestClaimCSV,
        },
        dispatch
    );

const connector = connect(mapStateToProps, mapDispatchToProps); 
type PropsFromRedux = ConnectedProps<typeof connector>
    
export default connector(ClaimList); 