import { call, put, take, select, takeEvery } from 'redux-saga/effects';
import { ActionType, action } from 'typesafe-actions';
import services from '../../services';
import * as actions from './actions';
import * as constants from './constants';
import * as models from '../../models'; 
import { CLAIM_ACTION_STATUSES, USER_ACTIONS } from '../../store/enums'; 
import * as financialActions from '../financials/actions'; 
import { userActions } from 'store/user';
import { AppToastTypes } from 'models/enums'; 
import * as helpers from '../../utils/helpers'; 

export default function* () {
    yield takeEvery(constants.REQUEST_ADD_CLAIM, addClaim); 
    yield takeEvery(constants.ADD_CLAIM_ATTACHMENT, addClaimAttachment); 
    yield takeEvery(constants.ADD_CLAIM_COMMENT, addClaimComment); 
    yield takeEvery(constants.ADD_CLAIM_INVOICE, addClaimInvoice); 
    yield takeEvery(constants.ADD_RESERVE_COVERAGE, addReserveCoverage); 
    yield takeEvery(constants.DELETE_CLAIM_INVOICE, deleteClaimInvoice); 
    yield takeEvery(constants.DELETE_CLAIM_ATTACHMENT, deleteClaimAttachment); 
    yield takeEvery(constants.DELETE_RESERVE_COVERAGE, deleteReserveCoverage); 
    yield takeEvery(constants.INITIALIZE_CLAIM, initializeClaim); 
    yield takeEvery(constants.PATCH_CLAIM, patchClaim); 
    yield takeEvery(constants.PATCH_CLAIM_RESERVE_COVERAGE, patchClaimReserveCoverage); 
    yield takeEvery(constants.PATCH_INSURED_PERSON, patchInsuredPerson);    
    yield takeEvery(constants.PATCH_LOSS_LOCATION, patchLossLocation); 
    yield takeEvery(constants.PATCH_POLICY, patchPolicy); 
    yield takeEvery(constants.REQUEST_ATTACHMENTS, requestAttachments);     
    yield takeEvery(constants.REQUEST_CLAIM_PARTY, requestClaimParty); 
    yield takeEvery(constants.REQUEST_CLAIM_SINGLE, requestClaimSingle);
    yield takeEvery(constants.REQUEST_CLAIM_SINGLE_BY_CLAIM_NUMBER, requestClaimSingleByClaimNumber); 
    yield takeEvery(constants.REQUEST_CLAIMS, requestClaims);
    yield takeEvery(constants.REQUEST_CLAIMS_CSV, requestClaimCSV);     
    yield takeEvery(constants.REQUEST_CLAIM_TYPE_LIST, requestClaimTypeList);
    yield takeEvery(constants.REQUEST_COMMENTS_ACTIVITY_LOG, requestActivityLog);  
    yield takeEvery(constants.REQUEST_COMMENTS_CORRECTION, requestCommentsCorrection);  
    yield takeEvery(constants.REQUEST_COMMENTS_RESERVE, requestCommentsReserve);  
    yield takeEvery(constants.REQUEST_EMAILED_CLAIMS, requestEmailedClaims); 
    yield takeEvery(constants.REQUEST_INVOICE_DRAFT, requestInvoiceDraft); 
    yield takeEvery(constants.REQUEST_INVOICES, requestInvoices); 
    yield takeEvery(constants.REQUEST_INSURED_PERSON, reqeustInsuredPerson); 
    yield takeEvery(constants.REQUEST_LOSS_CAUSES_DD, requestLossCausesDD); 
    yield takeEvery(constants.REQUEST_LOSS_LOCATION, requestLossLocation); 
    yield takeEvery(constants.REQUEST_MERGED_REPORTS, requestMergedReports); 
    yield takeEvery(constants.REQUEST_POLICY, requestPolicy); 
    yield takeEvery(constants.REQUEST_RESERVE_COVERAGES, requestReserveCoverages); 
    yield takeEvery(constants.REQUEST_STATUSES, requestStatuses); 
}

// Claims Search
export function* requestClaims(action: ActionType<typeof actions.requestClaims>):any {
    const userRequest = yield call(services.api.claim.getClaimsList, action.payload.request);
    if (userRequest.isSuccess) {
        yield put(actions.receiveClaims(userRequest.data));
    }
    else {
        console.log(userRequest); 
    }
}

// Claims Export
export function* requestClaimCSV(action: ActionType<typeof actions.requestClaimCSV>): any {
    const userRequest = yield call(services.api.claim.getClaimCSV, action.payload.request); 
    if (userRequest){        
        yield put(actions.receiveClaimCSV()); 
    }
}

// Claim Stuff
export function* addClaim(action: ActionType<typeof actions.addClaimRequest>): any {
    yield put(userActions.setLastUserAction(USER_ACTIONS.CLAIM_CREATING)); 
    const userRequest = yield call(services.api.claim.addClaim, action.payload.request); 
    if (userRequest.isSuccess) {
        yield put(actions.addClaimReceive()); 
        yield put(userActions.setLastUserAction(USER_ACTIONS.CLAIM_CREATING_SUCCESS)); 
        yield put(userActions.setAppToast({message: "Claim Created", type: AppToastTypes.Success})); 
    }
    else {     
        
        yield put(userActions.setLastUserAction(USER_ACTIONS.CLAIM_CREATING_FAILURE)); 
        yield put(userActions.setAppToast({message: helpers.getErrorsFromAPIResponse(userRequest.data), type: AppToastTypes.Failure}));      
    }
}

export function* addClaimAttachment(action: ActionType<typeof actions.addClaimAttachment>): any {
    const userRequest = yield call(services.api.claim.addClaimAttachment, action.payload.claimId, action.payload.documentType, action.payload.formData)
    if(userRequest.isSuccess) {
        yield put(actions.requestClaimSingle(action.payload.claimId));
    }
    else {
        console.log(userRequest.headers.location); 
    }
}

export function* addClaimComment(action: ActionType<typeof actions.addClaimComment>): any {
    const userRequest = yield call(services.api.claim.addClaimComment, action.payload.claimId, action.payload.comment)
    if(userRequest.isSuccess) {
        const commentType = action.payload.comment.commentType; 
        
        if (constants.ACTIVITY_LOG_TYPES.includes(commentType)){
            yield put(actions.requestCommentsActivityLog(action.payload.claimId, models.defaultPaging));         
        }
        if (constants.CORRECTION_TYPE.includes(commentType)){
            yield put(actions.requestCommentsCorrection(action.payload.claimId, models.defaultPaging));         
        }
        if (constants.RESERVES_TYPE.includes(commentType)){
            yield put(actions.requestCommentsReserve(action.payload.claimId, models.defaultPaging));         
        }
    }
    else {
        console.log(userRequest); 
    }
}

export function* addClaimInvoice(action: ActionType<typeof actions.addClaimInvoice>): any {
    yield put(userActions.setLastUserAction(USER_ACTIONS.CLAIM_CREATING_INVOICE));    
    const userRequest = yield call(services.api.claim.addClaimInvoice, action.payload.claimId, action.payload.request)        
    if(userRequest.isSuccess){        
        const invoiceId = parseInt(userRequest.data); 
        yield put(actions.requestInvoices(action.payload.claimId)); 
        yield put(financialActions.clearInvoice()); 
        const state = yield select(); 
        yield put(financialActions.requestInvoiceList(state.financials.financials.invoicesRequest));         
        yield put(userActions.setLastInvoiceCreatedId(invoiceId)); 
        yield put(userActions.setLastUserAction(USER_ACTIONS.CLAIM_CREATE_INVOICE_SUCCESS));
        yield put(userActions.setAppToast({message: "Invoice Created", type: AppToastTypes.Success})); 
    }  
    else {
        yield put(userActions.setLastUserAction(USER_ACTIONS.CLAIM_CREATE_INVOICE_FAILURE));
        yield put(userActions.setAppToast({message: userRequest.data, type: AppToastTypes.Failure})); 
    }
}

export function* addReserveCoverage(action: ActionType<typeof actions.addReserveCoverage>): any {
    const userRequest = yield call(services.api.claim.addReserveCoverage, action.payload.claimId, action.payload.request)
    if(userRequest.isSuccess) {
        yield put(actions.requestClaimSingle(action.payload.claimId));         
    }
    else {
        console.log(userRequest); 
    }
}

export function* deleteClaimInvoice(action: ActionType<typeof actions.deleteClaimInvoice>): any {
    yield put(userActions.setLastUserAction(USER_ACTIONS.INVOICE_DELETING)); 
    const userRequest = yield call(services.api.claim.deleteClaimInvoice, action.payload.claimId, action.payload.id, action.payload.reason)    
    if(userRequest.isSuccess) {        
        yield put(userActions.setAppToast({message: 'Invoice Deleted', type: AppToastTypes.Success})); 
        yield put(actions.requestInvoices(action.payload.claimId)); 
        yield put(userActions.setLastUserAction(USER_ACTIONS.INVOICE_DELETE_SUCCESS));         
    }
    else {
        const errorMessage = helpers.getResponseErrors(userRequest.data); 
        yield put(userActions.setLastUserAction(USER_ACTIONS.INVOICE_DELETE_FAILURE)); 
        yield put(userActions.setAppToast({message: errorMessage, type: AppToastTypes.Failure})); 
    }
}

export function* deleteClaimAttachment(action: ActionType<typeof actions.deleteClaimAttachment>): any {
    const userRequest = yield call(services.api.claim.deleteClaimAttachment, action.payload.claimId, action.payload.id)
    if(userRequest.isSuccess) {
        yield put(actions.setClaimActionStatus(CLAIM_ACTION_STATUSES.DELETE_ATTACHMENT_SUCCESS)); 
        yield put(actions.requestAttachments(action.payload.claimId)); 
    }
    else {
        yield put(actions.setClaimActionStatus(CLAIM_ACTION_STATUSES.DELETE_ATTACHMENT_FAILED)); 
    }
}

export function* deleteReserveCoverage(action: ActionType<typeof actions.deleteReserveCoverage>): any {
    const userRequest = yield call(services.api.claim.deleteReserveCoverage, action.payload.claimId, action.payload.id)
    if(userRequest.isSuccess) {
        yield put(actions.requestClaimSingle(action.payload.claimId)); 
    }
    else {
        console.log(userRequest); 
    }
}

export function* initializeClaim(action: ActionType<typeof actions.initializeClaim>): any {    
    // dispatch all actions to retrieve extra claim info...
    const claimID: number = action.payload.claim.claimID;
    yield put(actions.requestInsuredPerson(claimID));  
    yield put(actions.requestLossLocation(claimID)); 
    yield put(actions.requestInvoices(claimID)); 
    yield put(actions.requestPolicy(claimID)); 
    yield put(actions.requestReserveCoverages(claimID)); 
    yield put(actions.requestAttachments(claimID)); 
    yield put(actions.requestEmailedClaims(claimID)); 
    yield put(actions.requestMergedReports(claimID)); 
    yield put(actions.requestClaimParty(claimID)); 
}

export function* patchClaim(action: ActionType<typeof actions.patchClaim>): any {
    yield put(userActions.setLastUserAction(USER_ACTIONS.CLAIM_UPDATING)); 
    const userRequest = yield call(services.api.claim.patchClaim, action.payload.claimId, action.payload.patch)
    if (userRequest.isSuccess) {
        const state = yield select();                 
        yield put(actions.requestClaims(state.claims.search.claimsRequest)); 
        yield put(actions.requestClaimSingle(action.payload.claimId)); 
        yield put(userActions.setLastUserAction(USER_ACTIONS.CLAIM_UPDATE_SUCCESS)); 
        yield put(userActions.setAppToast({message: 'Claim Updated', type: AppToastTypes.Success}));
    }
    else {
        yield put(userActions.setAppToast({message: userRequest.data, type: AppToastTypes.Failure})); 
        yield put(userActions.setLastUserAction(USER_ACTIONS.CLAIM_UPDATE_FAILURE)); 
    }
}

export function* patchClaimReserveCoverage(action: ActionType<typeof actions.patchClaimReserveCoverage>): any {
    const userRequest = yield call(services.api.claim.patchReserveCoverage, action.payload.claimId, action.payload.id, action.payload.patch)
    if (userRequest.isSuccess) {
        yield put(actions.requestClaimSingle(action.payload.claimId)); 
    }
    else {
        console.log(userRequest); 
    }
}

export function* patchInsuredPerson(action: ActionType<typeof actions.patchInsuredPerson>): any {
    const userRequest = yield call(services.api.insuredPerson.patchInsuredPerson, action.payload.insuredPersonId, action.payload.patch)
    if (userRequest.isSuccess) {
        yield put(actions.requestClaimSingle(action.payload.claimId)); 
    }
    else {
        console.log(userRequest); 
    }
}

export function* patchLossLocation(action: ActionType<typeof actions.patchLossLocation>): any {
    const userRequest = yield call(services.api.claim.patchLossLocation, action.payload.claimId, action.payload.patch)
    if (userRequest.isSuccess) {
        yield put(actions.requestLossLocation(action.payload.claimId)); 
    }
    else {
        console.log(userRequest); 
    }
}

export function* patchPolicy(action: ActionType<typeof actions.patchPolicy>): any {
    const userRequest = yield call(services.api.claim.patchPolicy, action.payload.claimId, action.payload.patch)
    if (userRequest.isSuccess) {
        yield put(actions.requestPolicy(action.payload.claimId)); 
    }
    else {
        console.log(userRequest); 
    }
}

export function* requestActivityLog(action: ActionType<typeof actions.requestCommentsActivityLog>): any {
    const commentRequest: models.CommentsRequest = {
        ...action.payload.request,
        commentTypes: constants.ACTIVITY_LOG_TYPES,
    }
    const userRequest = yield call(services.api.claim.getClaimComments, action.payload.claimId, commentRequest); 
    if (userRequest.isSuccess) {
        yield put(actions.receiveCommentsActivityLog(userRequest.data)); 
    }
}

export function* requestAttachments(action: ActionType<typeof actions.requestAttachments>): any {        
    const userRequest = yield call(services.api.claim.getAttachments, action.payload.claimId); 
    if (userRequest.isSuccess) {
        yield put(actions.receiveAttachments(userRequest.data)); 
    }
    else {
        console.log(userRequest); 
    }
}

export function* requestCommentsCorrection(action: ActionType<typeof actions.requestCommentsCorrection>): any {    
    const commentRequest: models.CommentsRequest = {
        ...action.payload.request,
        commentTypes: constants.CORRECTION_TYPE,
    }
    const userRequest = yield call(services.api.claim.getClaimComments, action.payload.claimId, commentRequest); 
    if (userRequest.isSuccess) {
        yield put(actions.receiveCommentsCorrection(userRequest.data)); 
    }
}

export function* requestCommentsReserve(action: ActionType<typeof actions.requestCommentsReserve>): any {
    const commentRequest: models.CommentsRequest = {
        ...action.payload.request,
        commentTypes: constants.RESERVES_TYPE,
    }
    const userRequest = yield call(services.api.claim.getClaimComments, action.payload.claimId, commentRequest); 
    if (userRequest.isSuccess) {
        yield put(actions.receiveCommentsReserve(userRequest.data)); 
    }
}

export function* requestClaimParty(action: ActionType<typeof actions.requestClaimParty>):any {
    const userRequest = yield call(services.api.claim.getClaimParty, action.payload.claimId); 
    if (userRequest.isSuccess) {
        yield put(actions.receiveClaimParty(userRequest.data)); 
    }
}

export function* requestClaimSingle(action: ActionType<typeof actions.requestClaimSingle>):any {
    const state = yield select(); 
    let claim: models.ClaimSimple = state.claims.search.claimsListResponse.resourceList.find((x:models.ClaimSimple) => x.claimID === action.payload.claimId); 

    if(!claim) {
        const userRequest = yield call(services.api.claim.getClaimSingle, action.payload.claimId);    
        if (!userRequest.isSuccess) {
            console.log(userRequest); 
        }
        else 
            claim = userRequest.data; 
    }    
    yield put(actions.initializeClaim(claim)); 
}

export function* requestClaimSingleByClaimNumber(action: ActionType<typeof actions.requestClaimSingleByClaimNumber>):any {
    const claimsRequest: models.ClaimsRequest = {
        ...models.defaultClaimsRequest,
        insClaimNo: action.payload.claimNumber
    }
    const userRequest = yield call(services.api.claim.getClaimsList, claimsRequest);
    if (userRequest.isSuccess) {
        const claimsList: models.ClaimsListResponse = userRequest.data;
        if (claimsList.totalCount === 1){
            yield put(actions.requestClaimSingle(claimsList.resourceList[0].claimID));
        }
        else {
            console.log("Request claim by claim number returned multiple claims..."); 
        }
    }
}

export function* requestClaimTypeList(action: ActionType<typeof actions.requestClaimTypeList>): any {   
    const userRequest = yield call(services.api.claim.getClaimTypes, action.payload.request); 
    if (userRequest.isSuccess) {
        yield put(actions.receiveClaimTypeList(userRequest.data)); 
    }
}

export function* requestEmailedClaims(action: ActionType<typeof actions.requestEmailedClaims>): any {
    const userRequest = yield call(services.api.claim.getEmailedClaims, action.payload.claimId); 
    if (userRequest.isSuccess) {
        yield put(actions.receiveEmailedClaims(userRequest.data)); 
    }
}

export function* reqeustInsuredPerson(action: ActionType<typeof actions.requestInsuredPerson>): any {
    const userRequest = yield call(services.api.claim.getInsuredPerson, action.payload.claimId); 
    if (userRequest.isSuccess) {
        yield put(actions.receiveInsuredPerson(userRequest.data)); 
    }
    else 
        console.log(userRequest); 
}

export function* requestInvoiceDraft(action: ActionType<typeof actions.requestInvoiceDraft>): any {
    const userRequest = yield call(services.api.claim.getDraftInvoice, action.payload.claimId); 
    if (userRequest.isSuccess) {
        yield put(actions.receiveInvoiceDraft(userRequest.data)); 
    }
}

export function* requestInvoices(action: ActionType<typeof actions.requestInvoices>): any {
    const userRequest = yield call(services.api.claim.getInvoices, action.payload.claimId); 
    if (userRequest.isSuccess) {
        yield put(actions.receiveInvoices(userRequest.data)); 
    }
}

export function* requestLossLocation(action: ActionType<typeof actions.requestLossLocation>): any {
    const userRequest = yield call(services.api.claim.getLossLocation, action.payload.claimId); 
    if (userRequest.isSuccess) {
        yield put(actions.receiveLossLocation(userRequest.data)); 
    }
    else 
        console.log(userRequest); 
}

export function* requestMergedReports(action: ActionType<typeof actions.requestMergedReports>): any {
    const userRequest = yield call(services.api.claim.getMergedReports, action.payload.claimId); 
    if (userRequest.isSuccess) {
        yield put(actions.receiveMergedReports(userRequest.data)); 
    }
    else 
        console.log(userRequest); 
}

export function* requestPolicy(action: ActionType<typeof actions.requestPolicy>): any {
    const userRequest = yield call(services.api.claim.getPolicy, action.payload.claimId); 
    if (userRequest.isSuccess) {
        yield put(actions.receivePolicy(userRequest.data)); 
    }
    else 
        console.log(userRequest); 
}

export function* requestReserveCoverages(action: ActionType<typeof actions.requestReserveCoverages>): any {
    const userRequest = yield call(services.api.claim.getReserveCoverages, action.payload.claimId); 
    if (userRequest.isSuccess) {
        yield put(actions.receiveReserveCoverages(userRequest.data)); 
    }
    else 
        console.log(userRequest); 
}

export function* requestStatuses(action: ActionType<typeof actions.requestStatuses>): any {
    const userRequest = yield call(services.api.claim.getStatuses); 
    if (userRequest.isSuccess) {
        yield put(actions.receiveStatuses(userRequest.data)); 
    }
    else 
        console.log(userRequest); 
}

// dropdowns
export function* requestLossCausesDD(action: ActionType<typeof actions.requestLossCausesDD>): any {
    const userRequest = yield call(services.api.claim.getLossCausesDD); 
    if(userRequest.isSuccess) {
        yield put(actions.receiveLossCausesDD(userRequest.data)); 
    }
    else 
        console.log(userRequest); 
}