import MyTypes from 'MyTypes';
import * as React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect, ConnectedProps } from 'react-redux';
import { Col, Form, Image, OverlayTrigger, Table, Tooltip, Card } from 'react-bootstrap';
import * as models from '../../models';
import dateFormat from 'dateformat';
import NumberFormat from 'react-number-format';
import SingleActionButton from 'components/common/SingleActionButton'; 
import downloadIcon from '../../assets/images/Blue/download.svg';
import sortDesc from '../../assets/images/sort-up.svg'; 
import sortAsc from '../../assets/images/sort-down-alt.svg'; 
import receivePymtIcon from '../../assets/images/icons8-receive-dollar-50.png'; 
import QuickPayModal from './QuickPayModal';
import { financialsActions } from 'store/financials';
import _ from 'lodash'; 
import services from 'services';

interface Props extends PropsFromRedux {
    title: string; 
    isSortable: boolean; 
    onClick: (invoiceId: number) => void; 
}

interface InvoiceFeeShort {
    id: number; 
    amount: number; 
}

interface State {    
    invoiceShort: {
        title: string, 
        amount: number, 
        invoiceFees?: InvoiceFeeShort[],
    }, 
    isDesc: boolean,    
    quickPayTitle: string, 
    selectAll: boolean,      
    showQuickPayModal: boolean; 
    sortField: string,    
}

class InvoiceList extends React.Component<Props, State> {
    public state = {
        invoiceShort: {
            title: '',
            amount: 0, 
            invoiceFees: [],
        }, 
        sortField: this.props.invoicesRequest.sort,
        quickPayTitle: '', 
        isDesc: true, 
        showQuickPayModal: false,
        selectAll: false,          
    };

    componentDidUpdate(prevProps: any) {
        if (!_.isEqual(prevProps.selectedInvoices, this.props.selectedInvoices) || !_.isEqual(prevProps.invoicesListResponse, this.props.invoicesListResponse)) {
            this.evaluateSelectAllStatus(); 
        }        
    } 
    
    public sortTable(e: any) {
        const currentSortField = this.props.invoicesRequest.sort.replace("-",""); 
        const newSortField = e.target.id; 
        if (newSortField !== 'selectAll') {        
            const isFieldChanged = !(newSortField == currentSortField); 
            let isDesc = this.state.isDesc ; 
            if (isFieldChanged) {
                isDesc = (isFieldChanged ? !this.state.isDesc : false); 
                this.setState({sortField: newSortField, isDesc: isDesc});
            }
            else {
                isDesc = !isDesc; 
                this.setState({isDesc: isDesc});
            }

            const invoicesRequest: models.InvoicesRequest = {
                ...this.props.invoicesRequest, 
                sort: (isDesc ? "-" : "") + (isFieldChanged ? newSortField : currentSortField)
            }
            this.props.requestInvoices(invoicesRequest); 
        }
    }

    public exportClaims = async () => {
        const exportQuery: models.InvoicesRequest = {
            ...this.props.invoicesRequest,
            pageNo: 1,
            pageSize: this.props.invoicesListResponse.totalCount,
        }
        const response = await this.props.requestInvoicesCSV(exportQuery); 
    }

    public evaluateSelectAllStatus = () => {
        let selectAll:boolean = this.state.selectAll; 
        if(this.props.selectedInvoices.length === this.props.invoicesListResponse.resourceList.length) {
            selectAll = true; 
        }
        else {
            selectAll = false;
        }        
        this.setState({selectAll: selectAll});
    }

    public toggleInvoice = (e:any) => {
        const { selectedInvoices } = this.props; 
        const invoiceId: number = parseInt(e.target.value); 
                
        if (!selectedInvoices.includes(invoiceId))
        {
            var invoiceList: number[] = selectedInvoices; 
            invoiceList.push(invoiceId); 
        }
        else invoiceList = selectedInvoices.filter(i => i !== invoiceId);         
        this.props.setSelectedInvoices(invoiceList); 
        this.evaluateSelectAllStatus(); 
    }

    public toggleSelectAll = () => {
        var invoiceList: number[] = this.props.selectedInvoices;  

        if (invoiceList.length === this.props.invoicesListResponse.resourceList.length) {
            invoiceList = new Array<number>();
        }
        else {            
            {this.props.invoicesListResponse.resourceList?.map((x: models.InvoicesList, i: number) => (
                !this.props.selectedInvoices.includes(x.invoiceId) && invoiceList.push(x.invoiceId)
            ))}
        }
        this.evaluateSelectAllStatus(); 
        this.props.setSelectedInvoices(invoiceList); 
    }

    public handleQuickPay = async (i: models.InvoicesList) => {
        let props: {title: string, amount: number, invoiceFees: {id: number, amount: number}[]} = {title: '', amount: 0, invoiceFees: [{id: 0, amount: 0}]}; 
        if(i.invoiceGroup !== null) {
            const invoiceList = this.props.invoicesListResponse.resourceList.filter(x => x.invoiceGroup === i.invoiceGroup);             
            const feesList = invoiceList.map(a => a.invoiceFees).reduce((a, c) => a?.concat(c!)); 
            const invGrpTotal = invoiceList.reduce((a, c) => a = a + c.outstandingBalance, 0); ; 
            props = {title: 'Bulk: ' + i.invoiceGroup, amount: invGrpTotal, invoiceFees: feesList || new Array<{id: number, amount: number}>() }; 
        }
        else {
            const feeRequest: models.AdjusterFeeRequest = {
                invoiceId: i.invoiceId
            }
            const response = await services.api.financials.getAdjusterFeeList(feeRequest); 
    
            if(response.isSuccess){
                const adjusterFeeResponse: models.AdjusterFeeResponse = response.data; 
                props = {
                    title: 'Claim: ' + i.claimNumber, 
                    amount: i.outstandingBalance, 
                    invoiceFees: adjusterFeeResponse.resourceList.map(({invoiceFeeId, outstandingBalance}) => ({id: invoiceFeeId, amount: outstandingBalance})) || new Array<{id: number, amount: number}>()
                }; 
            }
            
        }        
        this.setState({invoiceShort: props}); 
        this.toggleQuickPayModal(); 
    }

    public toggleQuickPayModal = () => {
        this.setState({showQuickPayModal: !this.state.showQuickPayModal}); 
    }

    public render() {
        const { invoicesListResponse, isInvoiceListDownloading, isInvoiceListLoading, isSortable, title, selectedInvoices } = this.props;
        const sortField = this.props.invoicesRequest.sort.replace("-", ""); 
        const { isDesc, selectAll, showQuickPayModal, invoiceShort } = this.state;

        const sumGrandTotal = invoicesListResponse.resourceList.reduce((a, c) => a = a + c.grandTotal, 0); 
        const sumOutstandingBalance = invoicesListResponse.resourceList.reduce((a, c) => a = a + c.outstandingBalance, 0);         

        return (
            <>
            <QuickPayModal
                showModal={showQuickPayModal}
                closeModal={this.toggleQuickPayModal}
                invoiceShort={invoiceShort}
            />
            <Card className="claim-card">                
                {isInvoiceListDownloading && 
                    <span>Downloading...</span> 
                }
                <Card.Title>{title}                    
                    <SingleActionButton
                            action="Export"
                            title="Invoices"
                            img={downloadIcon}
                            onClick={this.exportClaims }
                            disabled={isInvoiceListDownloading}
                        />
                        <div className='table-hdr-totals'>
                        <Col>
                            Outstanding: <NumberFormat value={sumOutstandingBalance.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /> 
                        </Col>
                        <Col>
                            Total: <NumberFormat value={sumGrandTotal.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                        </Col>
                    </div>
                </Card.Title>
                {isInvoiceListLoading 
                    && <span>Loading...</span> 
                }   
                {invoicesListResponse?.totalCount > 0 &&
                    <Table striped>
                        <thead>
                            <tr 
                                className="claim-table-header"
                                onClick={this.sortTable.bind(this)}
                                >
                                <th id="isChecked">
                                    <Form>
                                        <Form.Group as={Col} controlId="selectAll">
                                            <Form.Check 
                                                name="selectAll"
                                                type="checkbox"
                                                id="selectAll"
                                                checked={selectAll}
                                                onChange={this.toggleSelectAll}
                                            />                                
                                        </Form.Group>
                                    </Form>
                                </th>
                                <th id="invoiceNumber">Invoice Number
                                    {isSortable && sortField ==="invoiceNumber" && <Image src={isDesc ? sortDesc : sortAsc}/>}
                                </th>
                                <th id="invoiceGroup">Invoice Group
                                    {isSortable && sortField ==="invoiceGroup" && <Image src={isDesc ? sortDesc : sortAsc}/>}
                                </th>
                                <th id="claimNumber">Claim Number
                                    {isSortable && sortField ==="claimNumber" && <Image src={isDesc ? sortDesc : sortAsc}/>}
                                </th>                                
                                <th id="insCompany">Ins Company
                                    {isSortable && sortField ==="insCompany" && <Image src={isDesc ? sortDesc : sortAsc}/>}
                                </th>
                                <th id="adjusterName">Adjuster
                                    {isSortable && sortField ==="adjusterName" && <Image src={isDesc ? sortDesc : sortAsc}/>}
                                </th>
                                <th id="invoiceDate">Invoice Date
                                    {isSortable && sortField ==="invoiceDate" && <Image src={isDesc ? sortDesc : sortAsc}/>}
                                </th>
                                <th id="outstandingBalance">Outstanding Balance
                                    {isSortable && sortField ==="outstandingBalance" && <Image src={isDesc ? sortDesc : sortAsc}/>}
                                </th>
                                <th id="grandTotal">Grand Total
                                    {isSortable && sortField ==="grandTotal" && <Image src={isDesc ? sortDesc : sortAsc}/>}
                                </th>
                                <th id="pay"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {invoicesListResponse.resourceList?.map((x: models.InvoicesList, i: number) => (
                                <tr key={x.invoiceId}>
                                    <td>
                                        <Form>
                                            <Form.Group as={Col} controlId="isChecked" className="input-field-container">
                                                <Form.Check 
                                                    name="isChecked"
                                                    type="checkbox"
                                                    id="chkSelected"
                                                    value={x.invoiceId}
                                                    checked={selectedInvoices.includes(x.invoiceId)}
                                                    onChange={(e) => this.toggleInvoice(e)}
                                                />                                
                                            </Form.Group>
                                        </Form>
                                    </td>
                                    <td 
                                        className="value-link" 
                                        onClick={() => this.props.onClick(x.invoiceId)} 
                                        >{x.invoiceNumber}
                                    </td>
                                    <td>{x.invoiceGroup}</td>
                                    <td>{x.claimNumber}</td>
                                    <td>{x.insCompany}</td>
                                    <td>{x.adjuster}</td>
                                    <td>{x.invoiceDate && dateFormat(x.invoiceDate, 'mm/dd/yyyy')}</td>
                                    <td><NumberFormat value={x.outstandingBalance.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /> </td>
                                    <td><NumberFormat value={x.grandTotal.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /> </td>
                                    <td>
                                        {x.outstandingBalance !== 0 && !x.invoiceGroup &&                                        
                                            <OverlayTrigger
                                                key="overlayTrigger"
                                                placement="top"
                                                overlay={<Tooltip className="my-tooltip" id="toolTip">Quick Pay (Full)</Tooltip>}
                                            >                                            
                                                <img onClick={(e) => this.handleQuickPay(x)} style={{height: "25px", cursor: "pointer"}} src={receivePymtIcon}/>
                                            </OverlayTrigger>
                                        
                                        }
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                }                
            </Card>
            </>
        );
    }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
    invoicesListResponse: state.financials.financials.invoicesListResponse, 
    invoicesRequest: state.financials.financials.invoicesRequest, 
    isInvoiceListDownloading: state.financials.financials.isInvoicesListDownloading, 
    isInvoiceListLoading: state.financials.financials.isInvoiceListLoading, 
    selectedInvoices: state.financials.financials.selectedInvoices, 
});

const mapDispatchToProps = (dispatch: Dispatch) => 
    bindActionCreators(
        {
            requestInvoices: financialsActions.requestInvoiceList, 
            requestInvoicesCSV: financialsActions.requestInvoicesCSV,             
            setSelectedInvoices: financialsActions.setSelectedIinvoices,
        },
        dispatch
    );

const connector = connect(mapStateToProps, mapDispatchToProps); 
type PropsFromRedux = ConnectedProps<typeof connector>
    
export default connector(InvoiceList); 