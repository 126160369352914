import MyTypes from 'MyTypes';
import * as React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { RouteComponentProps } from 'react-router';
import { connect, ConnectedProps } from 'react-redux';
import { Accordion, Card, Form, Button, Row, Col, CardGroup } from 'react-bootstrap';
import { claimsActions } from '../../store/claims'; 
import { companyActions } from 'store/companies';
import { contactsActions } from '../../store/contacts/';
import addIcon from '../../assets/images/Blue/add.svg';
import SingleActionButton from '../common/SingleActionButton';
import _ from 'lodash'; 
import * as models from '../../models/'
import ClaimList from './claimList'; 
import Pager from 'components/common/Pager';
import utils from '../../utils'; 
import ClaimCreateModal from 'components/claim-details/ClaimCreateModal';

interface Props extends PropsFromRedux {
}

interface State {
    claimsRequest: models.ClaimsRequest; 
    showCreateClaimModal: boolean; 
}

interface Params {
}

class ClaimsList extends React.PureComponent<RouteComponentProps<Params> & Props, State> {
    public state = {    
        claimsRequest: {
            ...this.props.claimsRequest,
            status: "Open,Reopened"
        }, 
        showCreateClaimModal: false,
    }

    public componentDidMount() {
        if(this.props.claimsListResponse.totalCount === 0) {
            this.fetchFormData(this.state.claimsRequest);
        }
        // Preload Dropdows for Claim related screens
        this.props.requestAgentCompanyDD(); 
        this.props.requestAdjustersDD(); 
        this.props.requestExaminersDD(); 
        this.props.requestFieldDirectorsDD(); 
        this.props.requestInsCompanyDD();
        this.props.requestMortgageCompanyDD(); 
        this.props.requestReviewersDD();
        this.props.requestStatuses(); 
    }

    componentDidUpdate(prevProps: any, prevState: any) { 
        if(!_.isEqual(prevProps.claimsListResponse, this.props.claimsListResponse)) {
            //if cascaded parameters only have one value, set form to value
            let newClaimRequest: models.ClaimsRequest = {
                ...this.state.claimsRequest
            }
            if(this.props.claimsListResponse.fieldAdjusters.length == 1){
                newClaimRequest = {
                    ...newClaimRequest,
                    fieldAdjuster: this.props.claimsListResponse.fieldAdjusters[0].name
                }
            }
            if(this.props.claimsListResponse.examiners.length == 1){
                newClaimRequest = {
                    ...newClaimRequest,
                    claimExaminer: this.props.claimsListResponse.examiners[0].name
                }
            }
            if(this.props.claimsListResponse.insuranceCompanies.length == 1){
                newClaimRequest = {
                    ...newClaimRequest,
                    insCompany: this.props.claimsListResponse.insuranceCompanies[0].name
                }
            }
            this.setState({claimsRequest: newClaimRequest}); 
        }
    }

    public pagerCallback = (paging: models.Paging) => {   
        const claimsRequest: models.ClaimsRequest = {
            ...this.state.claimsRequest,
            ...paging,
        }
        this.props.requestClaims(claimsRequest); 
    }; 

    private handleSearchSubmit = async (e: any) => {
        const { claimsRequest } = this.state; 
        this.props.history.push(this.props.location.pathname + "?" + utils.helpers.encodeQueryString(claimsRequest));
        this.fetchFormData(claimsRequest); 
        e.preventDefault(); 
    };

    public applyFilters = (e:any) => {        
        const { name, value } = e.target;
        let claimsRequest: models.ClaimsRequest;
        let isAdvancedSearch: boolean = false;  

        switch (name) {
            case 'claimExaminer': 
                claimsRequest = {
                    ...this.state.claimsRequest,
                    claimExaminer: value === "-1" ? null : value,
                };                
                break;            
            case 'claimStatus': 
                const statusList = value === "" ? null : value === "Open" ? "Open,Reopened" : value; 
                claimsRequest = {
                    ...this.state.claimsRequest,
                    status: statusList,
                };
                break;
            case 'fieldAdjuster': 
                claimsRequest = {
                    ...this.state.claimsRequest,
                    fieldAdjuster : value === "-1" ? null : value,
                };
                break;
            case 'closedDateFrom':             
                claimsRequest = {
                    ...this.state.claimsRequest, 
                    closedDateFrom: value === "" ? null : value,
                }; 
                isAdvancedSearch = true; 
                break; 
            case 'closedDateTo': 
                claimsRequest = {
                    ...this.state.claimsRequest,
                    closedDateTo: value === "" ? null : value, 
                }; 
                isAdvancedSearch = true; 
                break; 
            case 'insClaimNo': 
                claimsRequest = {
                    ...models.defaultClaimsRequest,
                    insClaimNo : value === "" ? null : value,
                };                  
                break; 
            case 'insCompany':
                claimsRequest = {
                    ...this.state.claimsRequest,
                    insCompany: value === "-1" ? null : value,
                };
                break; 
            case 'insPolicyNumber': 
                claimsRequest = {
                    ...this.state.claimsRequest, 
                    policyNumber: value === "" ? null : value,
                }; 
                break; 
            case 'lossCity': 
                claimsRequest = {
                    ...this.state.claimsRequest, 
                    lossCity: value === "" ? null : value,
                }; 
                isAdvancedSearch = true; 
                break;
            case 'lossCounty': 
                claimsRequest = {
                    ...this.state.claimsRequest,
                    lossCounty: value === "" ? null : value,
                }; 
                isAdvancedSearch = true; 
                break; 
            case 'lossState': 
                claimsRequest = {
                    ...this.state.claimsRequest,
                    lossState: value === "" ? null : value,
                }; 
                isAdvancedSearch = true; 
                break;
            case 'lossZipCode': 
                claimsRequest = {
                    ...this.state.claimsRequest,
                    lossZipCode: value === "" ? null : value,
                }; 
                isAdvancedSearch = true; 
                break; 
            case 'lossDateFrom': 
                claimsRequest = {
                    ...this.state.claimsRequest,
                    lossDateFrom: value === "" ? null : value, 
                }; 
                isAdvancedSearch = true; 
                break;
            case 'lossDateTo':
                claimsRequest = {
                    ...this.state.claimsRequest,
                    lossDateTo: value === "" ? null : value,
                }; 
                isAdvancedSearch = true; 
                break; 
            case 'receivedDateFrom': 
                claimsRequest = {
                    ...this.state.claimsRequest,
                    receivedDateFrom: value === "" ? null : value,
                }; 
                isAdvancedSearch = true; 
                break; 
            case 'receivedDateTo': 
                claimsRequest = {
                    ...this.state.claimsRequest, 
                    receivedDateTo: value === "" ? null : value,
                }; 
                isAdvancedSearch = true; 
                break; 
            case 'reportedDateFrom': 
                claimsRequest = {
                    ...this.state.claimsRequest,
                    reportedDateFrom: value === "" ? null : value,
                }; 
                isAdvancedSearch = true; 
                break; 
            case 'reportedDateTo': 
                claimsRequest = {
                    ...this.state.claimsRequest,
                    reportedDateTo: value === "" ? null : value,
                }; 
                isAdvancedSearch = true; 
                break; 
            default: 
                claimsRequest = {
                    ...this.state.claimsRequest,
                };
        }
        this.setState({claimsRequest: claimsRequest}); 
        if (!isAdvancedSearch || name === "insClaimNo"){
            this.fetchFormData(claimsRequest); 
            e.preventDefault(); 
        }
    }
    
    public fetchFormData = (request: models.ClaimsRequest) => {   
        this.props.requestClaims(request);
    }

    public resetFilters = () => {
        this.setState({claimsRequest: models.defaultClaimsRequest}); 
        this.fetchFormData(models.defaultClaimsRequest);        
    }

    public toggleCreateClaimModal = () => {
        this.setState({showCreateClaimModal: !this.state.showCreateClaimModal}); 
    }

    public render() {
        const { claimsRequest, showCreateClaimModal } = this.state; 
        const { claimsListResponse, statuses } = this.props; 
        const showPagination =  claimsListResponse?.totalCount > claimsRequest.pageSize ? true : false;       

        return (
            <>
                <ClaimCreateModal
                    showModal={showCreateClaimModal}
                    closeModal={this.toggleCreateClaimModal}
                /> 
                <Card className="claim-card">
                    <Card.Title>Claim Search
                        <SingleActionButton
                            action="Add"
                            title="Claim"
                            img={addIcon}
                            onClick={this.toggleCreateClaimModal}
                        />
                    </Card.Title>
                    <Form onSubmit={this.handleSearchSubmit}>
                        <Row>                    
                            <Col>
                                <Form.Group>
                                    <Form.Label>Claim Number</Form.Label>
                                    <Form.Control name="insClaimNo" placeholder='Claim #' value={claimsRequest.insClaimNo} onChange={this.applyFilters} />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Claim Status</Form.Label>                                
                                    <Form.Select name="claimStatus" value={claimsRequest.status?.includes('Open') ? 'Open' : claimsRequest.status} onChange={this.applyFilters} >
                                        <option key={-1} value=""></option>
                                        {statuses && statuses.resourceList.map((c: models.DropDownListItem, i: number) =>
                                            <option key={i} value={c.name}>{c.name}</option>
                                        )}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Insurance Company</Form.Label>
                                    <Form.Select name="insCompany" value={claimsRequest.insCompany} onChange={this.applyFilters} >
                                        <option key={-1} value=""></option>
                                        {claimsListResponse.insuranceCompanies && claimsListResponse.insuranceCompanies.map((c: models.DropDownListItem, i: number) => (                                             
                                            <option key={i} value={c.name} >{c.name}</option>
                                        ))}
                                    </Form.Select>                                    
                                </Form.Group>
                            </Col>                            
                            <Col>
                                <Form.Group>
                                    <Form.Label>Examiner</Form.Label>
                                    <Form.Select name="claimExaminer" value={claimsRequest.claimExaminer} onChange={this.applyFilters}>
                                        <option key={-1} value=""></option>
                                        {claimsListResponse.examiners && claimsListResponse.examiners.map((c: models.DropDownListItem, i:number) => 
                                            <option key={i} value={c.name}>{c.name}</option>
                                        )}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Field Adjuster</Form.Label>
                                    <Form.Select name="fieldAdjuster" value={claimsRequest.fieldAdjuster} onChange={this.applyFilters}>
                                        <option key={-1} value=""></option>
                                        {claimsListResponse.fieldAdjusters && claimsListResponse.fieldAdjusters.map((c: models.DropDownListItem, i: number) =>
                                            <option key={i} value={c.name}>{c.name}</option>
                                        )}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                        <Accordion className="accordion-card" defaultActiveKey="1">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>Advanced...</Accordion.Header>
                            <Accordion.Body>
                                <Row>
                                    <Form.Group as={Col}>
                                        <Form.Label>Policy #</Form.Label>
                                        <Form.Control name="insPolicyNumber" placeholder='Policy #...' value={claimsRequest.policyNumber} onChange={this.applyFilters} />
                                    </Form.Group>                                    
                                    <Form.Group as={Col}>
                                        <Form.Label>Loss City</Form.Label>
                                        <Form.Control name="lossCity" placeholder='Loss City...' value={claimsRequest.lossCity} onChange={this.applyFilters} />
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>Loss State</Form.Label>
                                        <Form.Control name="lossState" placeholder='Loss State...' value={claimsRequest.lossState} onChange={this.applyFilters} />
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>Loss County</Form.Label>
                                        <Form.Control name="lossCounty" placeholder='Loss County...' value={claimsRequest.lossCounty} onChange={this.applyFilters} />
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>Loss Zip Code</Form.Label>
                                        <Form.Control name="lossZipCode" placeholder='Loss Zip...' value={claimsRequest.lossZipCode} onChange={this.applyFilters} />
                                    </Form.Group>
                                </Row>
                                <Row>
                                    <CardGroup>
                                        <Card className="advanced-search-date">                                    
                                            <Card.Body>
                                                <Card.Title>Received Date</Card.Title>
                                                <Form.Group>
                                                    <Row>
                                                        <Form.Label column xs={4}>From</Form.Label>
                                                        <Col xs={8}>
                                                            <Form.Control 
                                                                type="date"
                                                                name="receivedDateFrom" 
                                                                value={claimsRequest.receivedDateFrom} 
                                                                onChange={this.applyFilters}
                                                                />
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Form.Label column xs={4}>To</Form.Label>
                                                        <Col xs={8}>
                                                            <Form.Control 
                                                                type="date"
                                                                name="receivedDateTo" 
                                                                value={claimsRequest.receivedDateTo} 
                                                                onChange={this.applyFilters}
                                                                />
                                                        </Col>
                                                    </Row>
                                                </Form.Group>
                                            </Card.Body>
                                        </Card>
                                        <Card className="advanced-search-date">
                                            <Card.Body>
                                                <Card.Title>Closed Date</Card.Title>
                                                <Form.Group>
                                                    <Row>
                                                        <Form.Label column xs={4}>From</Form.Label>
                                                        <Col xs={8}>
                                                            <Form.Control 
                                                                type="date"
                                                                name="closedDateFrom" 
                                                                value={claimsRequest.closedDateFrom} 
                                                                onChange={this.applyFilters}
                                                                />
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Form.Label column xs={4}>To</Form.Label>
                                                        <Col xs={8}>
                                                            <Form.Control 
                                                                type="date"
                                                                name="closedDateTo" 
                                                                value={claimsRequest.closedDateTo} 
                                                                onChange={this.applyFilters}
                                                                />
                                                        </Col>
                                                    </Row>
                                                </Form.Group>
                                            </Card.Body>
                                        </Card>
                                        <Card className="advanced-search-date">
                                            <Card.Body>
                                                <Card.Title>Loss Date</Card.Title>
                                                <Form.Group>
                                                    <Row>
                                                        <Form.Label column xs={4}>From</Form.Label>
                                                        <Col xs={8}>
                                                            <Form.Control 
                                                                type="date"
                                                                name="lossDateFrom" 
                                                                value={claimsRequest.lossDateFrom} 
                                                                onChange={this.applyFilters}
                                                                />
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Form.Label column xs={4}>To</Form.Label>
                                                        <Col xs={8}>
                                                            <Form.Control 
                                                                type="date"
                                                                name="lossDateTo" 
                                                                value={claimsRequest.lossDateTo} 
                                                                onChange={this.applyFilters}
                                                                />
                                                        </Col>
                                                    </Row>
                                                </Form.Group>
                                            </Card.Body>
                                        </Card>
                                        <Card className="advanced-search-date">
                                            <Card.Body>
                                                <Card.Title>Reported Date</Card.Title>
                                                <Form.Group>
                                                    <Row>
                                                        <Form.Label column xs={4}>From</Form.Label>
                                                        <Col xs={8}>
                                                            <Form.Control 
                                                                type="date"
                                                                name="reportedDateFrom" 
                                                                value={claimsRequest.reportedDateFrom} 
                                                                onChange={this.applyFilters}
                                                                />
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Form.Label column xs={4}>To</Form.Label>
                                                        <Col xs={8}>
                                                            <Form.Control 
                                                                type="date"
                                                                name="reportedDateTo" 
                                                                value={claimsRequest.reportedDateTo} 
                                                                onChange={this.applyFilters}
                                                                />
                                                        </Col>
                                                    </Row>
                                                </Form.Group>
                                            </Card.Body>
                                        </Card>                                       
                                    </CardGroup>
                                </Row>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>  
                        </Row>
                        <Button className="claim-search-btn" type='submit'>Search</Button>
                        <Button className="claim-search-btn" onClick={this.resetFilters}>Reset Filters</Button>
                    </Form>                                 
                </Card>                
                <ClaimList 
                        title="Search Results"
                        isSortable={true}
                    />                    
                {showPagination &&
                    <div>
                        <Pager
                            className = {"admin-pager"}
                            retrieveDataCallback = {this.pagerCallback}
                            pageSize = {claimsListResponse.resourceList?.length}
                            currentPage = {claimsRequest.pageNo}
                            totalCount = {claimsListResponse.totalCount}
                            pagesInRange = {3}
                        />
                    </div>
                }
            </>
        );
    }    
}

const mapStateToProps = (state: MyTypes.RootState) => ({
    claimsListResponse: state.claims.search.claimsListResponse, 
    isClaimsListLoading: state.claims.search.isClaimsListLoading, 
    claimsRequest: state.claims.search.claimsRequest,
    isClaimsListDownloading: state.claims.search.isClaimsListDownloading,
    statuses: state.claims.claim.statuses, 
});

const mapDispatchToProps = (dispatch: Dispatch) => 
    bindActionCreators(
        {
            requestAdjustersDD: contactsActions.requestAdjustersDD, 
            requestAgentCompanyDD: companyActions.requestAgentCompanyDD, 
            requestClaims: claimsActions.requestClaims,
            requestClaimCSV: claimsActions.requestClaimCSV,
            requestExaminersDD: contactsActions.requestExaminersDD, 
            requestFieldDirectorsDD: contactsActions.requestFieldDirectorsDD, 
            requestInsCompanyDD: companyActions.requestInsCompanyDD, 
            requestMortgageCompanyDD: companyActions.requestMortgageCompanyDD, 
            requestReviewersDD: contactsActions.requestReviewersDD, 
            requestStatuses: claimsActions.requestStatuses,
        },
        dispatch
    );

const connector = connect(mapStateToProps, mapDispatchToProps); 
type PropsFromRedux = ConnectedProps<typeof connector>
    
export default connector(ClaimsList); 