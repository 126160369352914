import MyTypes from 'MyTypes';
import * as React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect, ConnectedProps } from 'react-redux';
import { Accordion, Button, Card, Col, Form, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import * as models from '../../models'
import Pager from 'components/common/Pager';
import InvoiceList from './invoiceList'; 
import { financialsActions } from 'store/financials';
import AddInvoiceToBulkModal from './AddInvoiceToBulkModal';
import _ from 'lodash'; 
import infoIcon from '../../assets/images/Blue/info-circle.svg'; 
import Invoice from '../financials/Invoice'; 
import Reconcile from './Reconcile';
import { claimsActions } from 'store/claims';
import { userHasPermission } from '../../utils/helpers';


interface Props extends PropsFromRedux {
}

interface State {
    invoicesRequest: models.InvoicesRequest; 
    showAddInvoiceToBulkModal: boolean;
    showInvoiceModal: boolean;
    showReconcileModal: boolean; 
}

class Invoicing extends React.PureComponent<Props, State> {
    public state = {
        invoicesRequest: models.defaultInvoicesRequest,
        showAddInvoiceToBulkModal: false,
        showInvoiceModal: false, 
        showReconcileModal: false, 
    }

    public componentDidMount() {
        this.fetchFormData(this.state.invoicesRequest);
    }

    public pagerCallback = (paging: models.Paging) => { 
        const invoicesRequest: models.InvoicesRequest = {
            ...this.state.invoicesRequest,
            ...paging,
        } 
        this.props.requestInvoiceList(invoicesRequest); 
    }; 

    private handleSearchSubmit = async (e: any) => {
        const { invoicesRequest } = this.state; 
        this.fetchFormData(invoicesRequest); 
        e.preventDefault(); 
    };    

    public fetchFormData = (request: models.InvoicesRequest) => {        
        this.props.requestInvoiceList(request);
    }

    public applyFilters = (e:any) => {        
        const { name, value } = e.target;
        let request: models.InvoicesRequest;

        switch (name) {
            case 'age':
                request = {
                    ...this.state.invoicesRequest,
                    age: value === "-1" ? undefined : parseInt(value.split(' ')[0]),
                }; 
                break; 
            case 'adjuster': 
                request = {
                    ...this.state.invoicesRequest,
                    adjuster : value === "-1" ? null : value,
                };
                break;
            case 'invoiceDateFrom':             
                request = {
                    ...this.state.invoicesRequest, 
                    invoiceDateFrom: value === "" ? null : value,
                }; 
                break; 
                
            case 'invoiceDateTo': 
                request = {
                    ...this.state.invoicesRequest,
                    invoiceDateTo: value === "" ? null : value, 
                }; 
                break; 
            case 'invoiceGroup':
                request = {
                    ...this.state.invoicesRequest,
                    invoiceGroup: value === "" ? null : value, 
                }
                break;
            case 'insClaimNo': 
                request = {
                    ...this.state.invoicesRequest,
                    insClaimNo : value === "" ? null : value,
                };
                break; 
            case 'insCompany':
                request = {
                    ...this.state.invoicesRequest,
                    insCompany: value === "-1" ? null : value,
                };
                break; 
            case 'isUnpaid':
                request ={
                    ...this.state.invoicesRequest,
                    isUnpaid: !this.state.invoicesRequest.isUnpaid,
                }; 
                break; 
            default: 
                request = {
                    ...this.state.invoicesRequest,
                };
        }
        this.setState({invoicesRequest: request}); 
        this.fetchFormData(request); 
        e.preventDefault(); 
    }

    public resetFilters = () => {
        this.setState({invoicesRequest: models.defaultInvoicesRequest}); 
        this.fetchFormData(models.defaultInvoicesRequest);
    }

    public toggleAddInvoiceToBulkModal = () => {        
        if(!this.state.showAddInvoiceToBulkModal) {
            const list = this.props.invoicesListResponse.resourceList.filter(x2 =>
                this.props.selectedInvoices.some(x1 => x2.invoiceId === x1)
            ); 
            
            if(this.props.selectedInvoiceCount === 0) {
                alert("You must select at least one invoice!"); 
                return; 
            }
                
            const distinctCompanies = new Set(list.map(a => a.insCompany)).size;
            if(distinctCompanies !== 1)
                alert("All invoices must be for the same company!"); 
            else this.fetchGroupsAndShowModal(); 
        }
        else this.fetchGroupsAndShowModal(); 
    }

    public fetchGroupsAndShowModal = () => {
        const invoiceGroupRequest: models.InvoiceGroupsRequest = {
            ...models.defaultInvoiceGroupsRequest,
            companyName: this.props.invoicesListResponse.resourceList.find(x => x.invoiceId === this.props.selectedInvoices[0])?.insCompany || ''
        }
        this.props.requestInvoiceGroupByCompany(invoiceGroupRequest); 
        this.setState({showAddInvoiceToBulkModal: !this.state.showAddInvoiceToBulkModal}); 
    }

    public removeInvoiceFromGroup = () => {
        if(this.props.selectedInvoiceCount === 0) {
            alert("You must select at least one invoice!"); 
            return; 
        }
        this.props.selectedInvoices.forEach(e => this.props.removeInvoiceFromGroup(e));
        this.props.requestInvoiceList(this.state.invoicesRequest); 
        this.props.clearSelectedInvoices(); 
    }

    public handleEditInvoice = () => {
        if(this.props.selectedInvoiceCount != 1) {
            alert("You must select only one invoice to edit!"); 
            return; 
        }               
            
        this.editInvoice(this.props.selectedInvoices[0]);         
    }

    public editInvoice = (invoiceId: number) => {
        const claimNumber = this.props.invoicesListResponse.resourceList.find(x => x.invoiceId === invoiceId)?.claimNumber || "";
        if(this.props.claimNumber !== claimNumber){                                    
            this.props.requestClaim(claimNumber);
        }            
        this.props.requestInvoice(invoiceId); 
        this.toggleInvoiceModal(); 
    }
    
    public createInvoice = () => {
        if(this.props.selectedInvoiceCount === 0) {
            alert("You must select a claim to add invoice for!"); 
            return; 
        }
        if(this.props.selectedInvoiceCount > 1) {
            alert("You must select only one claim!"); 
            return; 
        }
        if(this.props.claimId === 0){                         
            const claimNumber = this.props.invoicesListResponse.resourceList.find(x => x.invoiceId === this.props.selectedInvoices[0])?.claimNumber || ""; 
            this.props.requestClaim(claimNumber);                        
        }        
        this.toggleInvoiceModal(); 
    }
        
    public toggleInvoiceModal = () => {
        this.setState({showInvoiceModal: !this.state.showInvoiceModal}); 
    }

    public toggleReconcileModal = () => {
        this.setState({showReconcileModal: !this.state.showReconcileModal}); 
    }
    public handleReconcileBulk = () => {
        if(this.props.selectedInvoiceCount !== 1) {
            alert("You must select a single bulk invoice to pay!"); 
            return; 
        }        
        // Load Invoice(s)
        const invoiceGroup = this.props.invoicesListResponse.resourceList.find(x => x.invoiceId === this.props.selectedInvoices[0])?.invoiceGroup || ""; 
        this.props.requestReconcileListBulk(invoiceGroup);
        this.toggleReconcileModal(); 
    }
    public handleReconcileSelected = () => {
        if(this.props.selectedInvoiceCount !== 1) {
            alert("You must select a single invoice to reconcile!"); 
            return; 
        }        
        // Load Invoice(s)
        this.props.requestReconcileList(this.props.selectedInvoices); 
        this.toggleReconcileModal(); 
    }

    public handleReconcile = () => {
        if(this.props.selectedInvoiceCount !== 1) {
            alert("Either select a single invoice or bulk!"); 
            return; 
        }
        const invoiceGroup = this.props.invoicesListResponse.resourceList.find(x => x.invoiceId === this.props.selectedInvoices[0])?.invoiceGroup; 
        if (invoiceGroup === undefined || invoiceGroup === null) {            
            this.props.requestReconcileList(this.props.selectedInvoices); 
        }
        else {
            this.props.requestReconcileListBulk(invoiceGroup);
        }
        this.toggleReconcileModal(); 
    }

    public render() {
        const { invoicesListResponse} = this.props; 
        const { invoicesRequest, showAddInvoiceToBulkModal, showInvoiceModal, showReconcileModal } = this.state; 
        const showPagination = true; 
        const dateBuckets: string[] = [
            '15 Days',
            '30 Days',
            '45 Days',
            '60 Days',
            '90 Days',
            '180 Days',
            '365 Days'
        ]; 
        const canCreate = userHasPermission("Can create invoices"); 

        return (
            <>            
            <AddInvoiceToBulkModal
                showModal={showAddInvoiceToBulkModal}
                closeModal={this.toggleAddInvoiceToBulkModal}
            />
            <Invoice 
                closeModal={this.toggleInvoiceModal}
                showModal={showInvoiceModal}                
            /> 
            <Reconcile
                closeModal={this.toggleReconcileModal}
                showModal={showReconcileModal}
            />
                <Card className="claim-card">
                    <Card.Title>Invoice Search</Card.Title>
                    <Form onSubmit={this.handleSearchSubmit}>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Claim Number</Form.Label>
                                    <Form.Control name="insClaimNo" placeholder='Claim #' value={invoicesRequest.insClaimNo} onChange={this.applyFilters} />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Insurance Company</Form.Label>
                                    <Form.Select name="insCompany" value={invoicesRequest.insCompany} onChange={this.applyFilters} >
                                        {<option key={-1}></option>}
                                        {invoicesListResponse.insuranceCompanies && invoicesListResponse.insuranceCompanies.map((c: models.DropDownListItem, i: number) => (                                             
                                            <option key={i} value={c.name} >{c.name}</option>
                                        ))}
                                    </Form.Select>                                    
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Adjuster</Form.Label>
                                    <Form.Select name="adjuster" value={invoicesRequest.adjuster} onChange={this.applyFilters}>
                                        {<option key={-1}></option>}
                                        {invoicesListResponse.fieldAdjusters && invoicesListResponse.fieldAdjusters.map((c: models.DropDownListItem, i: number) =>
                                            <option key={i} value={c.name}>{c.name}</option>
                                        )}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Bulk Invoice</Form.Label>
                                    <Form.Select name="invoiceGroup" value={invoicesRequest.invoiceGroup} onChange={this.applyFilters} >
                                        {<option key={-1}></option>}
                                        {invoicesListResponse.bulkInvoices && invoicesListResponse.bulkInvoices.map((c: models.DropDownListItem, i: number) => ( 
                                            <option key={i} value={c.name} >{c.name}</option>
                                        ))}
                                    </Form.Select>                                    
                                </Form.Group>
                            </Col>                                       
                        </Row>
                        <Row>
                            <Form.Group as={Col}>
                                    <Form.Label>Invoice Dt Start</Form.Label>                                    
                                        <Form.Control 
                                            type="date"
                                            name="invoiceDateFrom" 
                                            value={invoicesRequest.invoiceDateFrom} 
                                            onChange={this.applyFilters}
                                            />                                            
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label>Invoice Dt End</Form.Label>
                                    <OverlayTrigger
                                        key="overlayTrigger"
                                        placement="top"
                                        overlay={
                                            <Tooltip className="my-tooltip" id="toolTip">Search will include through midnight of End Date</Tooltip>
                                        }
                                    >
                                        <img className="info-icon" src={infoIcon}/>
                                    </OverlayTrigger>                                    
                                        <Form.Control 
                                            type="date"
                                            name="invoiceDateTo" 
                                            value={invoicesRequest.invoiceDateTo} 
                                            onChange={this.applyFilters}
                                            />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>Invoice Age</Form.Label>
                                <Form.Select name="age" value={invoicesRequest.age?.toString() + " Days" || undefined} onChange={this.applyFilters} >
                                    {<option key={-1}></option>}
                                    {dateBuckets.map((n: string, i: number) => ( 
                                        <option key={i} value={n} >{n}</option>
                                    ))}
                                </Form.Select>                                    
                            </Form.Group>                            
                            <Form.Group as={Col} controlId="isUnpaid" className="input-field-container">
                                <Form.Check 
                                    inline={true}
                                    name="isUnpaid"
                                    type="checkbox"
                                    label="Is Unpaid"
                                    id="chkUnpaid"
                                    checked={invoicesRequest.isUnpaid}
                                    onChange={this.applyFilters}
                                />                                
                            </Form.Group>
                        </Row>
                        <Button className="claim-search-btn" type='submit'>Search</Button>
                        <Button className="claim-search-btn" onClick={this.resetFilters}>Reset Filters</Button>
                    </Form>                                 
                </Card>
                <Accordion className="accordion-card" defaultActiveKey="1">
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>Invoice Actions...</Accordion.Header>
                        <Accordion.Body className="inv-accordion-body">
                            <Row>
                                <Col className='invoice-act-btn-grp'>
                                    <Row>
                                        <Col>
                                            <Button disabled={!canCreate} onClick={(e) => this.createInvoice()}>Create New Invoice (...on selected claim)</Button>
                                        </Col>
                                        <Col>
                                            <Button onClick={(e) => this.handleEditInvoice()}>Edit Invoice (...select 1)</Button>
                                        </Col>                                                                                                                 
                                    </Row>                            
                                    <Row>
                                        <Col>
                                            <Button onClick={(e) => this.toggleAddInvoiceToBulkModal()}>Add To Bulk</Button>
                                        </Col>
                                        <Col>
                                            <Button>Share Inoivce</Button>
                                        </Col>                                                                                
                                    </Row>                            
                                    <Row>
                                        <Col>
                                            <Button onClick={() => this.removeInvoiceFromGroup()}>Remove from Bulk</Button>
                                        </Col>                                        
                                        <Col>
                                            <Button onClick={(e) => this.handleReconcile()}>Reconcile</Button>
                                        </Col>                                                                                
                                    </Row>     
                                </Col>
                            </Row>                    
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
                <InvoiceList 
                    title="Search Results"
                    isSortable={true}
                    onClick={this.editInvoice}
                />    
                {showPagination &&
                    <div>
                        <Pager
                            className = {"admin-pager"}
                            retrieveDataCallback = {this.pagerCallback}
                            pageSize = {invoicesRequest.pageSize}
                            currentPage = {invoicesRequest.pageNo}
                            totalCount = {invoicesListResponse.totalCount}
                            pagesInRange = {3}
                        />
                    </div>
                }
            </>
        );
    }    
}

const mapStateToProps = (state: MyTypes.RootState) => ({      
    claimId: state.claims.claim.claim.claimID,     
    claimNumber: state.claims.claim.claim.insClaimNumber, 
    invoicesListResponse: state.financials.financials.invoicesListResponse,    
    isInvoiceListLoading: state.financials.financials.isInvoiceListLoading, 
    selectedInvoices: state.financials.financials.selectedInvoices,
    selectedInvoiceCount: state.financials.financials.selectedInvoices.length, 
});

const mapDispatchToProps = (dispatch: Dispatch) => 
    bindActionCreators(
        {            
            clearSelectedInvoices: financialsActions.clearSelectedInvoices, 
            requestClaim: claimsActions.requestClaimSingleByClaimNumber, 
            requestInvoice: financialsActions.requestInvoice,             
            removeInvoiceFromGroup: financialsActions.removeInvoiceFromGroup, 
            requestInvoiceList: financialsActions.requestInvoiceList, 
            requestInvoiceGroupByCompany: financialsActions.requestInvoiceGroupsList,
            requestReconcileList: financialsActions.requestReconcileList, 
            requestReconcileListBulk: financialsActions.requestReconcileListBulk, 
        },
        dispatch
    );

const connector = connect(mapStateToProps, mapDispatchToProps); 
type PropsFromRedux = ConnectedProps<typeof connector>
    
export default connector(Invoicing); 